import type { IncomingMessage, ServerResponse } from 'http'

import { COOKIES } from '@/constants'
import { serverSideRead as locationServerSideRead } from '@/context/location'
import { AUTO_DETECTED } from '@/context/location/constants'
import type { UserLocation } from '@/types/app-types'
import appLogger from '@/utils/app-logger'
import { getCookie, setCookie } from '@/utils/cookies'

export const COOKIE_DURATION_DAYS = 3

export const handleServerSideUserLocation = async (
    clientIp: string,
    res: ServerResponse<IncomingMessage> | undefined,
    cookieStr: string | undefined,
): Promise<{ storedLocation: UserLocation | undefined; currentLocation: UserLocation | undefined }> => {
    let currentLocation,
        storedLocation: UserLocation | undefined = undefined

    try {
        const locationResult = await locationServerSideRead({ clientIp })
        locationResult.method = AUTO_DETECTED
        currentLocation = locationResult

        const locationCookie = cookieStr ? (getCookie(cookieStr, COOKIES.USER_LOCATION_KEY) as UserLocation) : undefined
        storedLocation = locationCookie || locationResult

        if (!locationCookie) {
            setLocationCookie(storedLocation, res)
        }
    } catch (err) {
        console.error((err as Error).message)
        appLogger.logError(new Error(`Unable to set location cookie: ${(err as Error).message}`))
    } finally {
        return { currentLocation, storedLocation }
    }
}

const setLocationCookie = (location: UserLocation, res: ServerResponse<IncomingMessage> | undefined) => {
    const cookieExpiration = new Date()
    cookieExpiration.setDate(cookieExpiration.getDate() + COOKIE_DURATION_DAYS) // three days expiration

    res &&
        setCookie(res, {
            key: COOKIES.USER_LOCATION_KEY,
            value: JSON.stringify(location),
            path: '/',
            expirationDate: cookieExpiration,
            isSecure: process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'stage',
        })
}

export const normalizeLatLong = (location: {
    latitude: number | unknown
    longitude: number | unknown
}): string | undefined => {
    const { latitude, longitude } = location

    if (!latitude || !longitude) return
    return `${Number(latitude).toFixed(2)};${Number(longitude).toFixed(2)}`
}
