import type { ReactNode } from 'react'
import React, { useEffect, useLayoutEffect, useRef } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider } from '@mui/material'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { UserAttributes } from '@optimizely/optimizely-sdk'
import { createInstance as createOptimizelyNodeInstance } from '@optimizely/optimizely-sdk'
import type { ReactSDKClient } from '@optimizely/react-sdk'
import {
    createInstance,
    enums as OptimizelyEnums,
    OptimizelyDecideOption,
    OptimizelyProvider,
    setLogLevel,
} from '@optimizely/react-sdk'
import * as Sentry from '@sentry/nextjs'
import { I18nContext, I18nManager } from '@shopify/react-i18n'
import { Backdrop, BackdropConsumer, BackdropProvider } from '@vividseats/vivid-ui-kit'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import type { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useCookies } from 'react-cookie'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { ErrorBoundary } from 'react-error-boundary'
import { RecoilRoot } from 'recoil'
import { v4 as uuidv4 } from 'uuid'

import { legacyWebService, optimizelyService } from '@/api/factory'
import { COOKIES } from '@/constants'
import { InternationalizationProvider } from '@/context/internationalization'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '@/context/internationalization/constants'
import type { SelectedCurrency } from '@/context/internationalization/types'
import { getCountryConfigs } from '@/context/internationalization/utils'
import { UserLocationProvider } from '@/context/location'
import {
    PerformersNavigationProvider,
    serverSideRead as performersNavigationServerSideRead,
} from '@/context/performers-navigation'
import type { PerformersNavigation, PerformersNavigationStateType } from '@/context/performers-navigation/types'
import QueryProvider from '@/context/query'
import { TrendingsProvider, serverSideRead as trendingsServerSideRead } from '@/context/trendings'
import type { TrendingsState } from '@/context/trendings/types'
import { UserDataProvider } from '@/context/user-data'
import { theme } from '@/design-system/themes'
import { useInitFullStory } from '@/hooks/use-fullstory'
import { OPTIMIZELY_OFF_VALUE, FILTERED_USER_AGENTS, OPTIMIZELY_FEATURE } from '@/optimizely/constants'
import type { CurrencyConfig, Trending, UserData, UserLocation } from '@/types/app-types'
import { getCrmIDCookieValue } from '@/utils'
import { initializeAnalytics, trackErrorEvent, trackMarketingVisit, trackLandingPageView } from '@/utils/analytics'
import { trackPageView } from '@/utils/analytics/ga360'
import { rumIntialization } from '@/utils/analytics/RUM/datadogRUM'
import appLogger from '@/utils/app-logger'
import { cookieObjToString, getAllCookies, getCookie, getCookies, setCookie } from '@/utils/cookies'
import { handleServerSideUserLocation } from '@/utils/location/utils'
import {
    activateNotificationCallback,
    applyOptimizelyOverrides,
    setOptimizelyDecisionCookie,
} from '@/utils/optimizely/utils'
import { queryParamToStr } from '@/utils/query-strings'

import '@/styles/styles.scss'

import useRiskifiedBeacon from '../hooks/use-riskified-beacon'
// Global CSS must be imported in _app.tsx per Next
import '@vividseats/venue-maps-component/dist/components/VenueMap/vivid-override.css'

import NextError from './_error'

const { NEXT_PUBLIC_SENTRY_DSN } = process.env

if (typeof window !== 'undefined') {
    initializeAnalytics()
}

setLogLevel('warn')

const MAX_AGE_MS = 24 * 60 * 60 * 1000 // to be applied to both performer navigation and trendings request, as the current hermes cache wont work for server side requests

interface MyAppPageProps {
    userData: UserData
    initialPerformersNavigationProps: PerformersNavigationStateType
    initialTrendingsProps: TrendingsState
    optimizelyDatafile: Record<string, unknown> | null
    optimizelyInitString: string | null
    optimizelyUserId?: string
    deviceType: 'mobile' | 'tablet' | 'desktop' | null
    userLocation?: UserLocation
    currentLocation?: UserLocation
    currencyConfig?: CurrencyConfig
}

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc)
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(tz)

function MyApp(props: AppProps): ReactNode {
    const { Component, pageProps } = props
    const router = useRouter()

    useEffect(() => {
        rumIntialization()
    }, [])

    const typedPageProps = pageProps as MyAppPageProps
    const {
        userData,
        initialPerformersNavigationProps,
        initialTrendingsProps,
        optimizelyDatafile,
        optimizelyInitString,
        optimizelyUserId,
        deviceType,
        userLocation,
        currentLocation,
        currencyConfig,
    } = typedPageProps
    const isServer = typeof window === 'undefined'
    const userAgent = userData?.userAgent || (!isServer ? window?.navigator?.userAgent : undefined)
    const [{ optimizely_uuid }] = useCookies([COOKIES.OPTIMIZELY_USER_ID])
    const userId = optimizelyUserId || optimizely_uuid || uuidv4()

    const i18nManager = new I18nManager({
        locale: DEFAULT_LOCALE,
        currency: DEFAULT_CURRENCY,
    })

    useEffect(() => {
        const crmId = getCrmIDCookieValue()
        datadogRum.setUser({
            id: crmId === undefined ? 'Anonymous' : crmId, // [OPTIONAL] Setting up users
        })
    }, [])

    let initString = optimizelyInitString
    if (!userAgent || FILTERED_USER_AGENTS.some((ua) => userAgent.includes(ua))) {
        initString = OPTIMIZELY_OFF_VALUE
    }

    useEffect(() => {
        trackLandingPageView()
    }, [])

    useInitFullStory()

    // Instantiate Optimizely client and optionally apply overrides on the server side and on the client side
    // On the client side if the server hasn't provided Optimizely data file then fallback to fetching it asynchronously with SDK key
    const defaultDecideOptions = []
    if (isServer || initString === OPTIMIZELY_OFF_VALUE) {
        defaultDecideOptions.push(OptimizelyDecideOption.DISABLE_DECISION_EVENT)
    }
    const serverSideConfig = isServer
        ? {
              defaultDecideOptions,
              datafileOptions: {
                  // force autoupdate false on server side
                  autoUpdate: false,
              },
          }
        : {
              defaultDecideOptions,
          }
    const eventBatch = {
        eventBatchSize: 100,
        eventFlushInterval: 1000,
    }
    const optimizelyClient: ReactSDKClient = useRef(
        applyOptimizelyOverrides(
            createInstance(
                optimizelyDatafile
                    ? { datafile: optimizelyDatafile, ...eventBatch, ...serverSideConfig }
                    : {
                          sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_FULLSTACK_SDK_KEY,
                          ...eventBatch,
                          ...serverSideConfig,
                      },
            ),
            initString,
            userId,
        ),
    ).current

    const attributes = omitBy(
        {
            device_type: deviceType,
            $opt_user_agent: userAgent, // && encodeURIComponent(userAgent), Temporarily disabled due to WFO-380
        },
        isNil,
    ) as UserAttributes

    const pageTag = Component.displayName || Component.name

    // send optimizely events to GA4
    if (typeof document !== 'undefined') {
        document.cookie = `optimizely-experiments=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT"`
        document.cookie = `optimizely-features=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT"`
    }

    optimizelyClient.notificationCenter.clearNotificationListeners(OptimizelyEnums.NOTIFICATION_TYPES.ACTIVATE)
    optimizelyClient.notificationCenter.addNotificationListener(
        OptimizelyEnums.NOTIFICATION_TYPES.ACTIVATE,
        activateNotificationCallback,
    )

    /**
     * We use {@link useLayoutEffect()} here instead of {@link useEffect()} to
     * force the `marketing_visit` event/tag to fire before `pageview`, which is
     * required so that the `pageview` event is populated with UTM data in the
     * GTM data layer.
     */
    useLayoutEffect(() => {
        if (typeof window !== 'undefined') trackMarketingVisit(userData)
    }, [userData])

    useEffect(() => {
        if (typeof window !== 'undefined') trackPageView()
        if (NEXT_PUBLIC_SENTRY_DSN) Sentry.setTag('page_type', pageTag)
    })

    function onError(error: Error) {
        trackErrorEvent({
            error_type: error.name,
            error_message: error.message,
            error_code: error.name,
            error_url: document.URL,
        })
        optimizelyClient.close()
    }

    useRiskifiedBeacon(router.asPath, optimizelyClient)

    return (
        <>
            {/* Google Tag Manager */}
            <Script
                id="gtmStart"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `String.prototype.includes=String.prototype.includes||function(t){var n=!1;return-1!==this.indexOf(t)&&(n=!0),n};
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://gtm.vividseats.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ACCOUNT_ID}');`,
                }}
            />
            {/* End Google Tag Manager */}

            <ErrorBoundary
                onError={onError}
                FallbackComponent={(error) => (
                    <NextError
                        title={error?.error?.message || 'Internal server error'}
                        statusCode={500}
                        err={error?.error || new Error('An unknown error occurred')}
                        tag={pageTag}
                    />
                )}
            >
                <AppCacheProvider {...props}>
                    <ThemeProvider theme={theme}>
                        <OptimizelyProvider
                            optimizely={optimizelyClient}
                            isServerSide={!!optimizelyDatafile}
                            user={{ id: userId, attributes }}
                        >
                            <RecoilRoot>
                                <QueryProvider>
                                    <I18nContext.Provider value={i18nManager}>
                                        <UserDataProvider initialProps={userData}>
                                            <UserLocationProvider
                                                initialProps={{ data: userLocation, detectedLocation: currentLocation }}
                                            >
                                                <InternationalizationProvider
                                                    initialProps={{
                                                        storedCurrency: currencyConfig?.storedCurrency,
                                                        data: currencyConfig?.currencies,
                                                    }}
                                                >
                                                    <BackdropProvider>
                                                        <PerformersNavigationProvider
                                                            initialProps={initialPerformersNavigationProps}
                                                        >
                                                            <TrendingsProvider initialProps={initialTrendingsProps}>
                                                                <Head>
                                                                    <meta
                                                                        name="viewport"
                                                                        content="maximum-scale=5, initial-scale=1"
                                                                    />
                                                                </Head>

                                                                <Component {...pageProps} />
                                                            </TrendingsProvider>
                                                        </PerformersNavigationProvider>
                                                        <BackdropConsumer>
                                                            {({
                                                                state: { toggled, animated, lockScroll },
                                                                dispatch: { setToggled },
                                                            }) => {
                                                                return (
                                                                    <Backdrop
                                                                        isOpen={toggled}
                                                                        animated={animated}
                                                                        shouldLockBodyScoll={lockScroll}
                                                                        onClick={() => {
                                                                            setToggled({ toggled: false })
                                                                        }}
                                                                        zIndex="backdrop"
                                                                    />
                                                                )
                                                            }}
                                                        </BackdropConsumer>
                                                    </BackdropProvider>
                                                </InternationalizationProvider>
                                            </UserLocationProvider>
                                        </UserDataProvider>
                                    </I18nContext.Provider>
                                </QueryProvider>
                            </RecoilRoot>
                        </OptimizelyProvider>
                    </ThemeProvider>
                </AppCacheProvider>
            </ErrorBoundary>
        </>
    )
}

MyApp.getInitialProps = async ({ ctx }: AppContext): Promise<{ pageProps: MyAppPageProps }> => {
    const { req, query, res } = ctx
    const isServer = typeof window === 'undefined' && !!req && !!res

    let isAuthenticated = false,
        accountId = '',
        regionId = 0,
        regionName,
        userAgent: string | undefined,
        utmSource: string | undefined,
        utmMedium: string | undefined,
        utmCampaign: string | undefined,
        utmTerm: string | undefined,
        utmAdgroup: string | undefined,
        utmTarget: string | undefined,
        utmContent: string | undefined,
        utmPromo: string | undefined,
        performersNavigation: PerformersNavigation | undefined,
        trendings: Trending[] | undefined,
        optimizelyDatafile: Record<string, unknown> | null = null,
        optimizelyInitString: string | undefined,
        optimizelyUserId: string | undefined,
        // Regression flag may be passed in as a query parameter
        isRegression: boolean = query.regression === 'true',
        deviceType: 'mobile' | 'tablet' | 'desktop' | null = null,
        storedLocation: UserLocation | string | undefined = undefined,
        // Where the user is actually located based on IP since storedLocation could be any city/region the user has selected.
        currentLocation: UserLocation | undefined = undefined,
        currencyConfig: CurrencyConfig | undefined = undefined

    let cookieStr = isServer ? req?.headers.cookie : document.cookie

    await Promise.allSettled([
        (async () => {
            /*
                WARN: pixel.action call is critical!
                Its a tracking pixel to support session and attribution cookies from legacy web
                This should only fire if we do not have cookies created for the app yet
                Only new users who have never been on a VividSeats.com page before should hit this pixel
                https://vividseats.atlassian.net/browse/BW-113
            */
            if (
                isServer &&
                // Must have legacy env
                process.env.INTERNAL_LEGACY_WEB_URL &&
                // Do not call pixel action when receiving a 302/301 redirect
                // Cookies should have already been set in vivid-web
                req.statusCode !== 302 &&
                req.statusCode !== 301 &&
                // Keep next assets out of the equation
                !req.url?.includes('_next/data')
            ) {
                delete req.headers['host']

                try {
                    const response = await legacyWebService.get('pixel.action', { headers: req.headers, params: query })

                    res.setHeader('set-cookie', response.headers['set-cookie'] as string[])
                    const reqCookies = req.headers.cookie ? getAllCookies(req.headers.cookie) : null
                    const resCookies = getAllCookies((response.headers['set-cookie'] as string[]).join(';'))
                    req.headers.cookie = cookieObjToString({ ...reqCookies, ...resCookies })
                } catch (e) {
                    const message = (e as Error).message
                    console.error(message)
                    appLogger.logError(new Error(`pixel.action failed to load due to: ${message}`))
                }
            }
        })(),
        // Get performer navigation
        (async () => {
            if (!isServer) return

            try {
                performersNavigation = await performersNavigationServerSideRead(MAX_AGE_MS)
            } catch (err) {
                console.error((err as Error).message)
                appLogger.logError(new Error(`SSR query failed due to: ${(err as Error).message}`))
            }
        })(),
        // Get Trendings
        (async () => {
            if (!isServer) return

            try {
                trendings = await trendingsServerSideRead(MAX_AGE_MS)
            } catch (err) {
                console.error((err as Error).message)
                appLogger.logError(new Error(`SSR query failed due to: ${(err as Error).message}`))
            }
        })(),
        // Get Optimizely data file on the server side so that it's available for SSR
        (async () => {
            if (!isServer) return

            const optimizelyDatafileUrl = process.env.NEXT_PUBLIC_OPTIMIZELY_FULLSTACK_DATAFILE
            if (optimizelyDatafileUrl) {
                try {
                    optimizelyDatafile = (await optimizelyService.get(optimizelyDatafileUrl, { timeout: 10000 })).data
                } catch (err) {
                    console.error((err as Error).message)
                    appLogger.logError(
                        new Error(`Optimizely data file failed to load due to: ${(err as Error).message}`),
                    )
                }
            }
        })(),
        (async () => {
            const clientIp = req?.headers['cf-connecting-ip'] as string
            ;({ currentLocation, storedLocation } = await handleServerSideUserLocation(clientIp, res, cookieStr))
        })(),
        (async () => {
            if (isServer && cookieStr) {
                const currencyCookie = getCookie(cookieStr, COOKIES.PREFERRED_CURRENCY)

                if (currencyCookie) {
                    currencyConfig = {
                        storedCurrency: currencyCookie as SelectedCurrency,
                    }
                }
            }

            currencyConfig = await getCountryConfigs(
                req?.headers['cf-connecting-ip'] as string,
                currencyConfig?.storedCurrency,
            )
        })(),
    ])

    // Determine cookie string after pixel.action logic since that changes req.headers.cookie
    cookieStr = isServer ? req.headers.cookie : document.cookie

    if (req) {
        if (cookieStr) {
            // Get user data from cookies
            const cookiesList = getCookies(cookieStr, [
                COOKIES.AUTH_TOKEN_COOKIE,
                COOKIES.USER_DATA,
                COOKIES.CHANNEL_LIST,
                COOKIES.OPTIMIZELY_INIT_STRING,
                COOKIES.OPTIMIZELY_USER_ID,
                COOKIES.REGRESSION,
                COOKIES.USER_LOCATION_KEY,
            ])

            isAuthenticated = !!cookiesList[COOKIES.AUTH_TOKEN_COOKIE]
            accountId = cookiesList[COOKIES.AUTH_TOKEN_COOKIE]?.accountId || ''
            const userDataCookie = cookiesList[COOKIES.USER_DATA]
            if (userDataCookie) {
                regionId = userDataCookie.regionId
                regionName = userDataCookie.regionName
            }
            optimizelyInitString = cookiesList[COOKIES.OPTIMIZELY_INIT_STRING]
            optimizelyUserId = cookiesList[COOKIES.OPTIMIZELY_USER_ID]

            if (!isRegression) {
                // Regression flag may also be passed in as a cookie
                isRegression = cookiesList[COOKIES.REGRESSION]?.toString() === 'true'
            }

            const userChannelCookie = cookiesList[COOKIES.CHANNEL_LIST]
            if (userChannelCookie) {
                const channel = userChannelCookie.find((channel: Record<string, unknown>) => channel.s && channel.m)
                utmSource = channel?.s
                utmMedium = channel?.m
            }
        }

        // get parameters from the url, example
        // https://www.vividseats.com/leon-bridges-tickets/performer/40694?vkid=31898018&utm_source=google&utm_medium=cpc&utm_campaign=13247129428&utm_term=leon%20bridges%20tickets&adgroup=122262400026&target=aud-1517284907975:kwd-310868460418&device=c&wbraid=CjgKCAiA1JGRBhAkEigA7D32vsEJSN4LQ05Pn70C2q36VnGo0D-MOrrvojxPCJmnT67o9a5QGgI2UA&gclid=Cj0KCQiA95aRBhCsARIsAC2xvfwpDiORV8S5GktsREfb6T29HeBvMIg86qmkTPmEGXfC0daVGU9SdcQaAix-EALw_wcB
        const firstStringIn = (param: string | string[] | undefined): string | undefined => {
            // note that parameters may be an array of parameters. in that case just take the first.
            return typeof param === 'string' || param instanceof String ? (param as string) : param?.[0]
        }
        utmMedium = firstStringIn(query['utm_medium'])
        utmSource = firstStringIn(query['utm_source'])
        utmCampaign = firstStringIn(query['utm_campaign'])
        utmTerm = firstStringIn(query['utm_term'])
        utmAdgroup = firstStringIn(query['adgroup'])
        utmTarget = firstStringIn(query['target'])
        utmContent = firstStringIn(query['utm_content'])
        utmPromo = firstStringIn(query['utm_promo'])

        userAgent = req.headers['user-agent']
        if (userAgent) {
            try {
                // See https://github.com/duskload/react-device-detect/blob/master/docs/selectors.md
                const { isMobileOnly, isTablet, isDesktop } = getSelectorsByUserAgent(userAgent)
                if (isMobileOnly) {
                    deviceType = 'mobile'
                } else if (isTablet) {
                    deviceType = 'tablet'
                } else if (isDesktop) {
                    deviceType = 'desktop'
                }
            } catch (err) {
                console.error((err as Error).message)
            }
        }
    }

    // Add ability to override Optimizely feature flags and experiments (for QA purposes)
    // - on/true/enabled/default - (Default) Use Optimizely feature flags and experiments as normally, e.g. ?optimizely=on
    // - off/false/disabled - Ignore all Optimizely feature flags and experiments, e.g. ?optimizely=off
    // - CSV - Enable only those Optimizely feature flags and experiments that are provided in the list, e.g. ?optimizely=feature1,experiment2:variation3
    if (query.optimizely || isRegression) {
        optimizelyInitString = isRegression ? OPTIMIZELY_OFF_VALUE : (queryParamToStr(query.optimizely) as string)
        res && setCookie(res, { key: COOKIES.OPTIMIZELY_INIT_STRING, value: optimizelyInitString, path: '/' })
    }

    // On the first visit to the site (per session) set a cookie with a unique Optimizely user Id
    if (!optimizelyUserId) {
        if (cookieStr) {
            const userDataCookie: any = getCookie(cookieStr, COOKIES.USER_DATA)
            if (userDataCookie && userDataCookie.uuid) {
                optimizelyUserId = userDataCookie.uuid
            }
        }
        if (!optimizelyUserId) {
            optimizelyUserId = uuidv4()
        }
        const cookieExpiration = new Date()
        cookieExpiration.setTime(cookieExpiration.getTime() + 365 * 24 * 60 * 60 * 1000)
        res &&
            setCookie(res, {
                key: COOKIES.OPTIMIZELY_USER_ID,
                value: optimizelyUserId,
                path: '/',
                expirationDate: cookieExpiration,
                isSecure: process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'stage',
            })
    }

    // Set cookies for the optimizely decisions taken
    if (optimizelyDatafile && res) {
        const instance = createOptimizelyNodeInstance({ datafile: optimizelyDatafile })
        const userContext = instance && instance.createUserContext(optimizelyUserId)

        // Get and store a decision for the Cognito feature
        const cognitoFeature = OPTIMIZELY_FEATURE.cognitoAuthentication
        userContext && setOptimizelyDecisionCookie(userContext, optimizelyUserId, cognitoFeature, res)
    }

    return {
        pageProps: {
            userData: {
                isAuthenticated,
                accountId,
                regionId,
                regionName,
                userAgent,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmAdgroup,
                utmTarget,
                utmContent,
                utmPromo,
                isDesktop: deviceType === 'desktop',
            },
            currencyConfig,
            userLocation: storedLocation,
            currentLocation,
            initialPerformersNavigationProps: { data: performersNavigation },
            initialTrendingsProps: { data: trendings },
            optimizelyDatafile,
            optimizelyInitString: optimizelyInitString || null,
            optimizelyUserId,
            deviceType,
        },
    }
}

export default MyApp
