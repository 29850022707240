import dayjs from 'dayjs'
import type { DateRange } from 'react-day-picker'

import type { Listing } from '@/context/checkout/listing/types'

export const fromLocalDateString = (stringDate: string): dayjs.Dayjs =>
    dayjs(stringDate.replace(/(?:Z|([+-])(\d{2}):?(\d{2}))\[.*?\]/, ''))

export const shortDateFromLocalString = (stringDate: string): string =>
    fromLocalDateString(stringDate).format('MM/DD/YYYY').toString()

export const timeFromLocalString = (stringDate: string): string =>
    fromLocalDateString(stringDate).format('h:mma').toString()

export const timeCapitalizedFromLocalString = (stringDate: string): string =>
    fromLocalDateString(stringDate).format('h:mm A').toString()

export const formatDateForLDJSON = (stringDate?: string): string =>
    dayjs(stringDate).format('YYYY-MM-DDTHH:mm:ss').toString()

export const getDateTime = (dateTime: { date: string; time?: string }): string => {
    return dateTime.time
        ? dateTime.time.includes('TBD')
            ? `${dateTime.date} ${String.fromCharCode(8226)} ${dateTime.time}`
            : `${dateTime.date} at ${dateTime.time}`
        : dateTime.date
}

// may want to replace use of getDateTime with getProductionPageDateTime
export const getProductionPageDateTime = (dateTime: { date: string; time?: string }): string => {
    const { date, time } = dateTime

    const dateIsTbd = date === 'Date TBD'
    const timeIsTbd = time === 'Time TBD' || !time

    const formattedDate = dateIsTbd ? 'TBD' : date
    const formattedTime = timeIsTbd ? 'TBD' : time

    return !dateIsTbd && !timeIsTbd
        ? `${formattedDate} at ${formattedTime}`
        : `Date: ${formattedDate} | Time: ${formattedTime}`
}

export const getDateAndTime = (listing: Listing): string | undefined => {
    const hasEventStarted = dayjs().isAfter(fromLocalDateString(listing.production.localDate))

    if (listing.production.dateTbd) {
        return `Date TBD`
    }

    if (!listing.production.dateTbd && listing.production.timeTbd) {
        return `${fromLocalDateString(listing.production.localDate).format('dddd MMMM D, YYYY')} TBD`
    }

    if (!listing.production.dateTbd && !listing.production.timeTbd && !hasEventStarted) {
        return `${fromLocalDateString(listing.production.localDate).format('dddd MMMM D, YYYY')} ${timeFromLocalString(
            listing.production.localDate,
        )}`
    }

    if (!listing.production.dateTbd && !listing.production.timeTbd && hasEventStarted) {
        return `${fromLocalDateString(listing.production.localDate).format('dddd MMMM D, YYYY')} Event Started`
    }
}

export const formatDateRange = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): string => {
    let formattedRange = startDate.format('MMM D')
    if (!startDate.isSame(endDate, 'day')) {
        if (startDate.month() === endDate.month()) {
            formattedRange += ` - ${endDate.date()}` // mm/dd - dd
        } else {
            formattedRange += ` - ${endDate.format('MMM D')}` // mm/dd - mm/dd
        }
    }
    return formattedRange
}

export const formatDateWithConditionalYear = (dateObject: dayjs.Dayjs): string => {
    return formatDateWithPattern(dateObject, dateObject.year() === dayjs(new Date()).year() ? 'MMM D' : 'MMM D, YYYY')
}

export const formatDateToDDD = (dateObject: dayjs.Dayjs): string => {
    return formatDateWithPattern(dateObject, 'ddd')
}

export const formatDateToHMMA = (dateObject: dayjs.Dayjs): string => {
    return formatDateWithPattern(dateObject, 'h:mma')
}

export const formatDateWithDayWithConditionalYear = (dateObject: dayjs.Dayjs): string => {
    if (dateObject.year() === dayjs(new Date()).year()) {
        return formatDateWithPattern(dateObject, 'ddd, MMM D')
    }
    return formatDateWithPattern(dateObject, 'ddd, MMM D, YYYY')
}

export const formatDateWithPattern = (dateObject: dayjs.Dayjs, pattern: string): string => {
    return dateObject.format(pattern).toString()
}

export const monthDateFromLocalString = (stringDate: string): string =>
    fromLocalDateString(stringDate).format('MMM D').toString()

export const dateAndTimeFromLocalString = (stringDate: string): string =>
    fromLocalDateString(stringDate).format('ddd • h:mma').toString()

export const daysUntil = (stringDate2: string): number => {
    const date2 = dayjs(stringDate2)
    return date2.diff(dayjs(), 'day')
}

// Converts a DateRange object to a ProductionDates object, for use in the date-filter context
export const dateRangeToProductionDates = (
    range?: DateRange,
): {
    startDate?: string
    endDate?: string
    isSingleDay?: boolean
} => {
    const { from = '', to = '' } = range || {}

    if (!from) {
        return { startDate: undefined, endDate: undefined, isSingleDay: undefined }
    }

    // Catalog expects both from and to to match if we want to select a single day
    const day = to || from

    return {
        startDate: from.toISOString().split('T')[0],
        endDate: day.toISOString().split('T')[0],
        isSingleDay: dayjs(from).isSame(dayjs(day), 'day'),
    }
}
