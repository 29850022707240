import type { Components, Theme } from '@mui/material'

import type { TextFieldOverrideProps } from './themeTypes'

const components: Components<Omit<Theme, 'components'>> = {
    MuiTypography: {
        defaultProps: {
            fontFamily: "'GT-Walsheim', sans-serif",
        },
    },
    MuiButtonBase: {
        styleOverrides: {
            root: ({ theme }) => ({
                // TODO: add more conditionals as color options get set up
                ':disabled': {
                    color: theme.colors.gray?.['300'],
                },
                ':hover': {
                    color: theme.palette.neutral?.dark,
                },
            }),
        },
        defaultProps: {
            disableRipple: true,
            disableTouchRipple: true,
        },
    },
    MuiButton: {
        styleOverrides: {
            // TODO: properly type ownerState
            root: ({ ownerState, theme }: { ownerState: any; theme: Theme }) => ({
                ...(ownerState.size === 'small' && {
                    height: '2rem',
                    minWidth: '2rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    gap: '0.25rem',
                }),
                ...(ownerState.size === 'medium' && {
                    height: '2.5rem',
                    minWidth: '2.5rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    gap: '0.5rem',
                }),
                ...(ownerState.size === 'large' && {
                    height: '3rem',
                    minWidth: '3rem',
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    gap: '0.5rem',
                }),
                ...(ownerState.variant === 'outlined' && {
                    ...(ownerState.color === 'primary' && {
                        ':hover': {
                            backgroundColor: theme.palette.primary.lightest,
                            color: theme.palette.primary.main,
                        },
                        ':active': {
                            backgroundColor: theme.palette.primary.lightest,
                            color: theme.palette.primary.dark,
                            borderColor: theme.palette.primary.dark,
                        },
                    }),
                    ...(ownerState.color === 'secondary' && {
                        color: theme.palette.neutral?.main,
                        borderColor: theme.palette.neutral?.light,
                        ':hover': {
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            borderColor: theme.palette.secondary.main,
                        },
                        ':active': {
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            borderColor: theme.palette.secondary.dark,
                        },
                    }),
                    ...(ownerState.color === 'primary' && {
                        color: theme.palette.primary?.main,
                        borderColor: theme.palette.primary?.main,
                        ':hover': {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                        },
                        ':active': {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            borderColor: theme.palette.primary.dark,
                        },
                    }),
                    ...(ownerState.color === 'neutral' && {
                        color: theme.palette.neutral.main,
                        borderColor: theme.palette.neutral.light,
                        ':hover': {
                            backgroundColor: theme.palette.neutral.lighter,
                            color: theme.palette.neutral.main,
                            borderColor: theme.palette.neutral.light,
                        },
                        ':active': {
                            backgroundColor: theme.palette.neutral.lighter,
                            color: theme.palette.neutral.main,
                            borderColor: theme.palette.neutral.dark,
                        },
                    }),
                    ':disabled': {
                        color: theme.colors.gray?.['300'],
                        borderColor: theme.palette.neutral?.light,
                    },
                }),
                ...(ownerState.variant === 'contained' && {
                    boxShadow: 'none',
                    ':hover': {
                        boxShadow: 'none',
                    },
                    ':active': {
                        boxShadow: 'none',
                    },
                    ...(ownerState.color === 'primary' && {
                        ':active': {
                            backgroundColor: theme.palette.primary.darkest,
                            boxShadow: 'none',
                        },
                        ':hover': {
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.neutral?.contrastText,
                            boxShadow: 'none',
                        },
                    }),
                    ...(ownerState.color === 'neutral' && {
                        border: `0.0625rem solid transparent`,
                        boxShadow: 'none',
                        color: theme.palette.neutral?.main,
                        backgroundColor: theme.palette.neutral?.contrastText,
                        ':hover': {
                            border: `0.0625rem solid transparent`,
                            backgroundColor: theme.palette.neutral?.lighter,
                            boxShadow: 'none',
                        },
                        ':active': {
                            border: `0.0625rem solid ${theme.palette.neutral?.light}`,
                            backgroundColor: theme.palette.neutral?.lighter,
                            boxShadow: 'none',
                        },
                    }),
                    ':disabled': {
                        color: theme.colors.gray?.['300'],
                        backgroundColor: theme.palette.neutral?.contrastText,
                    },
                }),
            }),
        },
        defaultProps: {
            disableFocusRipple: true,
        },
    },
    MuiChip: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                gap: '0.25rem',
                width: 'auto',
                ...(ownerState.size === 'small' && {
                    padding: '0.25rem 0.5rem',
                    height: '2rem',
                }),
                ...(ownerState.size === 'medium' && {
                    padding: '0.5rem 0.75rem',
                    height: '2.5rem',
                }),
                ...(ownerState.disabled && {
                    color: theme.colors.gray?.['300'],
                    borderColor: theme.colors.gray?.['200'],
                }),
                ...(ownerState.variant === 'outlined' && {
                    ...(ownerState.color === 'primary' && {
                        borderColor: theme.colors.gray?.['200'],
                        boxShadow: 'none',
                        '>.MuiChip-deleteIcon': {
                            color: theme.palette.primary?.main,
                        },
                        '&.MuiChip-root:hover': {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.lightest,
                            borderColor: theme.palette.primary.main,
                            boxShadow: 'none',
                        },
                        '&.MuiChip-root:active': {
                            color: theme.palette.primary?.dark,
                            borderColor: theme.palette.primary?.dark,
                            boxShadow: 'none',
                            '.MuiChip-deleteIcon': {
                                color: theme.palette.primary?.dark,
                            },
                        },
                        '&.Mui-disabled': {
                            '.MuiChip-deleteIcon': {
                                color: theme.colors.gray?.['300'],
                            },
                        },
                    }),
                    ...(ownerState.color === 'secondary' && {
                        color: theme.colors.gray?.['800'],
                        borderColor: theme.colors.gray?.['200'],
                        boxShadow: 'none',
                        '>.MuiChip-deleteIcon': {
                            color: theme.palette.secondary?.dark,
                        },
                        '>.MuiChip-icon': {
                            color: theme.palette.secondary?.dark,
                        },
                        '&.Mui-disabled': {
                            color: theme.colors.gray?.['300'],
                            borderColor: theme.colors.gray?.['200'],
                            '.MuiChip-icon': {
                                color: theme.colors.gray?.['300'],
                            },
                            '.MuiChip-deleteIcon': {
                                color: theme.colors.gray?.['300'],
                            },
                        },
                        '&.MuiChip-root:hover': {
                            color: theme.palette.secondary?.dark,
                            backgroundColor: theme.palette.secondary?.light,
                            borderColor: theme.palette.secondary?.main,
                            boxShadow: 'none',
                            '>.MuiChip-deleteIcon': {
                                color: theme.palette.secondary?.dark,
                            },
                        },
                        '&.MuiChip-root:active': {
                            color: theme.palette.secondary?.dark,
                            borderColor: theme.palette.secondary?.dark,
                            boxShadow: 'none',
                            '&.MuiChip-deleteIcon': {
                                color: theme.palette.secondary?.dark,
                            },
                        },
                    }),
                    ...(ownerState.color === 'info' && {
                        borderColor: theme.colors.gray?.['200'],
                        color: theme.palette.neutral.main,
                        boxShadow: 'none',
                        '>.MuiChip-deleteIcon': {
                            color: theme.colors.gray?.['800'],
                        },
                        '&.Mui-disabled': {
                            color: theme.colors.gray?.['300'],
                            borderColor: theme.colors.gray?.['200'],
                            '.MuiChip-deleteIcon': {
                                color: theme.colors.gray?.['300'],
                            },
                        },
                        '&.MuiChip-root:hover': {
                            backgroundColor: theme.palette.neutral.lighter,
                            boxShadow: 'none',
                            '.MuiChip-deleteIcon': {
                                color: theme.colors.gray?.['800'],
                            },
                        },
                        '&.MuiChip-root:active': {
                            backgroundColor: theme.palette.neutral.lighter,
                            borderColor: theme.palette.neutral?.dark,
                            boxShadow: 'none',
                            '.MuiChip-deleteIcon': {
                                color: theme.colors.gray?.['800'],
                            },
                        },
                    }),
                }),
                '.MuiChip-label': {
                    paddingLeft: '0rem',
                    paddingRight: '0rem',
                    fontSize: '1rem',
                    '&.MuiChip-labelSmall': {
                        fontSize: '0.875rem',
                    },
                },
                '.MuiChip-icon': {
                    marginLeft: 0,
                    marginRight: 0,
                },
                '.MuiChip-deleteIcon': {
                    marginLeft: 0,
                    marginRight: 0,
                },
            }),
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.colors.gray[600],
                borderColor: theme.colors.gray[200],
                fontWeight: 'normal',
                // default height is too tall
                height: '2.25rem',
                // keep reasonable dimensions for smaller buttons
                minWidth: '3.5rem',

                // round border of first and last button
                '&.MuiToggleButtonGroup-grouped:first-of-type': {
                    borderRadius: '1.5rem 0rem 0rem 1.5rem',
                },
                '&.MuiToggleButtonGroup-grouped:last-of-type': {
                    borderRadius: '0rem 1.5rem 1.5rem 0rem ',
                },

                '&.Mui-selected': {
                    // stops weird border clipping
                    zIndex: 2,
                    fontWeight: '500',
                },

                // if you add a new color to this component's props
                // add the following properties for it below

                '&.MuiToggleButton-secondary': {
                    '&.Mui-selected': {
                        color: theme.colors.teal[800],
                        borderColor: theme.colors.teal[600],
                        backgroundColor: theme.colors.teal[100],
                    },
                },

                '&.MuiToggleButton-primary': {
                    '&.Mui-selected': {
                        color: theme.colors.magenta[800],
                        borderColor: theme.colors.magenta[600],
                        backgroundColor: theme.colors.magenta[100],
                    },
                },

                '&.MuiToggleButton-standard': {
                    '&.Mui-selected': {
                        color: theme.colors.gray[800],
                        borderColor: theme.colors.gray[600],
                        backgroundColor: theme.colors.gray[100],
                    },
                },
            }),
        },
    },
    MuiSkeleton: {
        styleOverrides: {
            root: ({ theme }: { theme: Theme }) => ({
                '&.MuiSkeleton-rounded': {
                    // when we get rid of ui-kit, this color will need to change
                    // for now match with UI-kit skeleton colors
                    backgroundColor: theme.colors.gray?.['200'],
                    borderRadius: 32,
                },
                '&.MuiSkeleton-rectangular': {
                    /**
                        TODO:
                        This is for src/components/shared/production-listings/components/production-listings-skeleton/component.tsx.
                        Ideally we should be using 'rounded', but the overrides above prevent that.
                        We will need to revisit this when we get rid of the ui-kit skeleton loader
                    */
                    backgroundColor: theme.colors.gray?.['100'],
                    borderRadius: 4,
                },
            }),
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ ownerState, theme }: TextFieldOverrideProps) => ({
                '& .MuiInputLabel-shrink': {
                    top: 0,
                },
                ...(ownerState.success && {
                    '& label': {
                        color: theme.colors.green?.['600'],
                    },
                    '& label.Mui-focused': {
                        color: theme.colors.green?.['600'],
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.colors.green?.['600'],
                        },
                        '&:hover fieldset': {
                            borderColor: theme.colors.green?.['600'],
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.colors.green?.['600'],
                        },
                    },
                    '& .MuiFormHelperText-root': {
                        color: theme.colors.green?.['600'],
                    },
                }),
            }),
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                fontWeight: 700,
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderBottom: `1px solid ${theme.colors.gray?.[100]}`,
            }),
        },
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.colors.gray?.[400],
                fontWeight: 'normal',
                '&.Mui-selected': {
                    color: theme.colors.gray?.[800],
                    fontWeight: 'bold',
                },
            }),
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: () => ({
                overflowX: 'hidden',
            }),
        },
    },
    MuiDialog: {
        styleOverrides: {
            root: () => ({
                zIndex: 7000,
                background: 'rgba(4, 9, 44, 0.6)',
            }),
        },
    },
}

export default components
