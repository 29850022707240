import type { ServerResponse } from 'http'
import type { GetServerSidePropsContext } from 'next'

import {
    CRM_ID_COOKIE,
    GA_USER_ID_COOKIE,
    GUEST_EMAIL_COOKIE,
    PERMANENT_COOKIE_SECONDS,
    PRICE_GROUP_COOKIES,
    RETURNING_USER_COOKIE,
    USER_DATA_COOKIE,
    VTRK_COOKIE,
    WSUSER_COOKIE,
} from '@/constants'
import { USER_LOCATION_KEY } from '@/context/location/constants'
import type { CookieValue, ObjectWithKeyIteration, UserLocation } from '@/types/app-types'

export const getCookie = (cookiesStr: string, key: string): CookieValue => {
    const cookieMatch = cookiesStr.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
    const cookie =
        cookieMatch && cookieMatch[0] ? decodeURIComponent(`${cookieMatch.pop()}`).replace(/^"|"$/g, '') : null // also strip leading and/or trailing double quotes if there are any

    if (cookie) {
        try {
            return JSON.parse(cookie)
        } catch {
            return cookie
        }
    }
    return ''
}

export const getCookies = (cookiesStr: string, keys: string[]): any => {
    const keyValues: { [key: string]: CookieValue } = {}
    keys.forEach((key) => {
        keyValues[key] = getCookie(cookiesStr, key)
    })

    return keyValues
}

export const getAllCookies = (cookiesStr: string): ObjectWithKeyIteration => {
    const cookieObj: ObjectWithKeyIteration = {}
    cookiesStr.split(';').map((c) => {
        const [key, ...v] = c.split('=')
        return (cookieObj[key.trim()] = v.join('='))
    })

    return cookieObj
}

export const getCookieFromResponseHeaders = (
    key: string,
    context?: GetServerSidePropsContext,
): CookieValue | undefined => {
    const setCookieHeaders = context?.res.getHeader('Set-Cookie')
    const responseCookies = Array.isArray(setCookieHeaders) ? setCookieHeaders.join(';') : undefined
    return responseCookies ? getCookie(responseCookies, key) : undefined
}

export interface ClientCookieOptions {
    key: string
    value: string
    path?: string
    expires?: string
    maxAge?: number
    isSecure?: boolean
}

export interface CookieOptions {
    key: string
    value: string
    path?: string
    expirationDate?: Date
    isSecure?: boolean
}

export const setClientCookie = ({
    key,
    value,
    path = '/',
    expires,
    maxAge,
    isSecure = false,
}: ClientCookieOptions): void => {
    // Append new cookie
    const pathSegment: string = path ? `; path=${path}` : ''
    const expiresSegment: string = expires ? `; expires=${expires}` : ''
    const maxAgeSegment: string = maxAge ? `; max-age=${maxAge}` : ''
    const secure: string = isSecure ? `; secure=${isSecure}` : ''
    document.cookie = `${key}=${encodeURIComponent(value)}${pathSegment}${expiresSegment}${maxAgeSegment}${secure}`
}

export const deleteClientCookie = (key: string, path = '/'): void => {
    // Clear cookie by setting it to a past expiration date
    setClientCookie({ key, value: '', path, expires: 'Thu, 01 Jan 1970 00:00:01 GMT' })
}

export const setCookie = (
    res: Pick<ServerResponse, 'getHeader' | 'setHeader'>,
    { key, value, path, expirationDate, isSecure }: CookieOptions,
): void => {
    // Get current cookies first
    let currentCookies: string | string[] | number = res.getHeader('set-cookie') || []
    if (typeof currentCookies === 'string') {
        currentCookies = [currentCookies]
    } else if (typeof currentCookies === 'number') {
        currentCookies = []
    }

    // Append new cookie
    const pathSegment: string = path ? `; path=${path}` : ''
    res.setHeader('set-cookie', [
        ...currentCookies,
        `${key}=${value}${pathSegment}${expirationDate ? ';expires=' + expirationDate.toUTCString() : ''}${
            isSecure ? ';Secure' : ''
        }`,
    ])
}

export const deleteCookie = (res: Pick<ServerResponse, 'getHeader' | 'setHeader'>, key: string): void => {
    setCookie(res, {
        key,
        value: 'deleted',
        expirationDate: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
        isSecure: ['production', 'stage'].includes(process.env.ENVIRONMENT || ''),
    })
}

export const cookieObjToString = (cookieObj: ObjectWithKeyIteration): string => {
    return Object.entries(cookieObj)
        .map(([key, value]) => `${key}=${value}`)
        .join(';')
}

export const getVTRKCookieValue = (cookieRegex: string): string | undefined => {
    let value
    const vtrk = `${getCookie(document.cookie, VTRK_COOKIE) || ''}`
    if (vtrk) {
        const matches = new RegExp(cookieRegex, 'g').exec(vtrk)
        if (matches && matches.length === 2) {
            value = matches[1]
        }
    }

    return value
}

export const getUUIDCookieValue = (): string | undefined => {
    let uuid
    const userData = getCookie(document.cookie, USER_DATA_COOKIE)
    if (userData) {
        uuid = (userData as ObjectWithKeyIteration).uuid
    }
    return uuid
}

// NOTE: this is currently set by legacy or pixel.action
export const getGaUserIDCookieValue = (): string | undefined => {
    const value = getCookie(document.cookie, GA_USER_ID_COOKIE).toString()
    if (value === '') return undefined
    return value
}

// NOTE: this is currently set by legacy or pixel.action
export const getCrmIDCookieValue = (): string | undefined => {
    const value = getCookie(document.cookie, CRM_ID_COOKIE).toString()
    if (value === '') return undefined
    return value
}

// get the guest email address for tracking. do not trust this as their real email address.
// NOTE: this is currently set by legacy or pixel.action
export const getGuestEmailCookieValue = (): string | undefined => {
    const value = getCookie(document.cookie, GUEST_EMAIL_COOKIE).toString()
    if (value === '') return undefined
    return value
}

// NOTE: this is currently set by legacy or pixel.action
export const getReturningUserCookieValue = (): boolean | undefined => {
    const value = getCookie(document.cookie, RETURNING_USER_COOKIE).toString()
    if (value === 'true') return true
    if (value === 'false') return false

    return undefined
}

export const updateReturningUserCookie = (isReturningUser: boolean): void => {
    const cookieExists = !!getCookie(document.cookie, RETURNING_USER_COOKIE)
    if (isReturningUser && !cookieExists) {
        setClientCookie({ key: RETURNING_USER_COOKIE, value: 'true', maxAge: PERMANENT_COOKIE_SECONDS })
    } else if (!isReturningUser && cookieExists) {
        deleteClientCookie(RETURNING_USER_COOKIE)
    }
}

// NOTE: this is currently set by legacy or pixel.action
export const getWsUserCookieValue = (): string | undefined => {
    const value = getCookie(document.cookie, WSUSER_COOKIE).toString()
    if (value === '') return undefined
    return value
}

export const getPriceGroupCookieValue = (): string | undefined => {
    let value: string | undefined
    PRICE_GROUP_COOKIES.forEach((priceGroupCookie) => {
        if (!value) {
            const priceGroupValue = getCookie(document.cookie, priceGroupCookie)
            if (priceGroupValue) {
                value = `${priceGroupCookie}=${priceGroupValue};`
            }
        }
    })

    return value
}

export const getPriceGroupId = (): string | undefined => {
    return PRICE_GROUP_COOKIES.map((c) => getCookie(document.cookie, c))
        .find((v) => v)
        ?.toString()
}

export const getRegionNameCookieValue = (): string | undefined => {
    let regionName
    const userData = getCookie(document.cookie, USER_DATA_COOKIE)
    if (userData) {
        regionName = (userData as ObjectWithKeyIteration).regionName
    }
    return regionName
}

export const getUtmPromoCookieValue = (): string | undefined => {
    const userData = getCookie(document.cookie, USER_DATA_COOKIE)
    return (userData as ObjectWithKeyIteration)?.utmPromo
}

export const deleteUtmPromoCookieValue = (): void => {
    const userData = getCookie(document.cookie, USER_DATA_COOKIE)
    const userDataObject = userData as ObjectWithKeyIteration
    if (userDataObject.utmPromo) {
        delete userDataObject.utmPromo
        setClientCookie({ key: USER_DATA_COOKIE, value: JSON.stringify(userData) })
    }
}

export const getUserLocationCookieValue = (): UserLocation | undefined => {
    const value = getCookie(document.cookie, USER_LOCATION_KEY)
    return value as ObjectWithKeyIteration as UserLocation
}
