import React from 'react'

import { vividStyles } from '@vividseats/vivid-ui-kit'

import { USER_LOCATION_KEY } from '@/context/location/constants'
import { AnalyticsIds } from '@/context/utils/analytics'
import type { CategoryIdMap, Option } from '@/types/app-types'
import { PageType } from '@/types/app-types'

const { NEXT_PUBLIC_SUPPORT_URL } = process.env

export const URLS = {
    CORP: 'https://corporate.vividseats.com',
    CORP_STAGE: 'https://staging.corporate.vividseats.com',
    APP_STORE_LINK: 'https://apps.apple.com/ca/app/vivid-seats-buy-tickets/id963355757',
    BUYER_GUARANTEE_DISCLAIMER:
        'https://support.vividseats.com/support/solutions/articles/11000083676-information-regarding-the-coronavirus-impact',
    CAREERS: 'https://corporate.vividseats.com/careers/',
    COVID_SUPPORT: `//${NEXT_PUBLIC_SUPPORT_URL}/support/solutions/articles/11000083676-information-regarding-the-coronavirus-impact`,
    GIFT_CARDS_FAQ: 'https://support.vividseats.com/support/solutions/articles/11000063369-gifting-faqs',
    GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.vividseats.android',
    INVESTORS: 'https://investors.vividseats.com',
    PARTNERS: 'https://corporate.vividseats.com/partners/',
    SELLER_FAQ: 'https://support.vividseats.com/support/solutions/1000129384',
    VIVIDSEATS: 'https://www.vividseats.com',
    VIVIDSEATS_MOBILE_APP: 'https://corporate.vividseats.com/app/',
    VIVIDSEATS_FACEBOOK: 'https://www.facebook.com/VividSeats/',
    VIVIDSEATS_INSTAGRAM: 'https://www.instagram.com/vividseats/',
    VIVIDSEATS_TWITTER: 'https://twitter.com/vividseats',
} as const

export const ENVIRONMENTS = { prod: URLS.VIVIDSEATS, local: 'localhost', stage: 'vivid-web-athena' } as const

export const PATHS = {
    ACCESSIBILITY: '/accessibility',
    ACCOUNT: '/account/dashboard',
    LEGACY_ACCOUNT: '/account/Account.action',
    AFFILIATES: '/affiliates',
    BLOG: '/blog/',
    BUYER_GUARANTEE: '/guarantee',
    BROADWAY: '/theater/broadway',
    CHECKOUT: '/checkout/default.action',
    CONTACT_US: '/contact-us',
    DO_NOT_SELL: '/privacy/donotsell',
    DELETE_MY_ACCOUNT: '/privacy/deletemyaccount',
    EMAIL_FORM: '/widget/EmailForm.action',
    EXISTING_ORDERS: '/contact-us/existing-orders',
    LEGACY_FORGOT_PASSWORD: '/account/PasswordReset.action',
    FORGOT_PASSWORD: '/account/password-reset',
    GIFT_CARDS: '/gift-center',
    GUARANTEE: '/guarantee',
    LARGE_SELLERS_PROGRAM: '/sell/overview.html#skyboxQualifying',
    LEGACY_LISTING_DETAIL: '/account/ListingDetails.action',
    LEGACY_SALES_DETAIL: '/account/FanSale.action',
    LEGACY_LOGIN: '/Login.action',
    LOGIN: '/login',
    LEGACY_LOGOUT: '/Login.action?logout',
    LOGOUT: '/login?logout',
    NFL_SUB_CATEGORY: '/nfl',
    ORDERS: '/account/orders',
    LEGACY_ORDERS: '/account/OrderHistory.action',
    ORDER_DETAIL: '/account/orders/[id]',
    PRESS: '/press/',
    PRIVACY_POLICY: '/privacy',
    PROFILE: '/account/profile',
    LEGACY_PROFILE: '/account/Profile.action',
    RELIABILITY: '/blog/is-vivid-seats-legit',
    REVIEWS: '/blog/vivid-seats-reviews',
    REWARDS_TERMS_AND_CONDITIONS: '/rewards-program-terms',
    REWARDS: '/rewards',
    SALES: '/account/listing-manager',
    LEGACY_SALES: '/account/ListingManager.action#sales',
    SEARCH: '/search',
    SELL_TICKETS: '/sell/overview',
    SELL_TICKETS_ACTION: '/fan/SellTicket.action',
    STUDENT_DISCOUNT: '/student-discount',
    SELLER_TERMS_AND_CONDITIONS: '/sellerterms',
    SELLING_TICKETS: '/contact-us/selling-tickets',
    SHOPPING_FOR_TICKETS: '/contact-us/shopping-for-tickets',
    SITEMAP: '/sitemap',
    SITEMAP_PERFORMERS: '/sitemap/performers',
    SITEMAP_VENUES: '/sitemap/venues',
    TERMS_AND_CONDITIONS: '/terms',
    TOP_PICK_EXTERNAL_IMAGE_MID_PATH: '/t_homepage_carousel_card_image_v2',
    TOP_PICK_EXTERNAL_IMAGE_WIDE_PATH: '/t_homepage_carousel_card_image_wide',
    WWE_SUB_CATEGORY: '/wwe',
} as const

export const PARKING_WEB_PATH_INDICATOR = '-parking-tickets'

export const FAQ_PATH = 'https://support.vividseats.com/support/home'

export const COUNTRY_FLAG_IMAGE_PATH = 'https://a.vsstatic.com/web-app/flag-icons'

export const CONTACT_US_FAQ_PAGE_TYPES = {
    EXISTING_ORDERS: 'existing-orders',
    SHOPPING: 'shopping',
    SELLING: 'selling',
} as const

export const PAID_SEARCH_URL_APPEND = '?utm_source=google&utm_medium=cpc'

// For skip to footer/content buttons
export const ACCESSIBILITY_IDS = {
    CONTENT: 'content',
    FOOTER: 'footer',
} as const

export const USER_DATA_COOKIE = 'userData'
export const COOKIES = {
    AUTH_TOKEN_COOKIE: 'at',
    SOCIAL_REDIRECT_DATA: 'social-redirect-data',
    USER_DATA: USER_DATA_COOKIE,
    CHANNEL_LIST: 'ch',
    /** Possible values:
     *  - on/true/enabled/default - (Default) Use Optimizely feature flags and experiments as normally
     *  - off/false/disabled - Ignore all Optimizely feature flags and experiments
     *  - CSV - Enable only those Optimizely feature flags and experiments that are provided in the list, e.g. feature1,experiment2:variation3 */
    OPTIMIZELY_INIT_STRING: 'optimizely-init-string',
    OPTIMIZELY_USER_ID: 'optimizely_uuid',
    OPTIMIZELY_DECISION_COOKIE: 'optimizely_decision',
    REGRESSION: 'regression',
    SEGMENT_ANONYMOUS_USER: 'ajs_anonymous_id',
    SEGMENT_LOGGEDIN_USER: 'ajs_user_id',
    USER_LOCATION_KEY: USER_LOCATION_KEY,
    PREFERRED_CURRENCY: 'preferred-currency',
    PRIVACY_COOKIE: 'cmapi_cookie_privacy',
} as const

export const AUTH_HEADERS = {
    X_ACCOUNT: 'x-account',
    X_AUTH_TOKEN: 'x-auth-token',
    X_REFRESH_TOKEN: 'x-refresh-token',
    X_LEGACY_AUTH_COOKIE: 'x-legacy-auth-cookie',
}

export const HEADERS = {
    X_PLACES_SESSION_TOKEN: 'x-places-session-token',
} as const

export const SESSION_STORAGE_KEYS = {
    OPTIMIZELY_USER_EXPERIMENTS: 'optimizely-user-experiments',
    FIRST_PAGE_URL: 'first-page-url',
} as const

export const STYLES = {
    ...vividStyles,
    headerHeight: {
        mobile: 48,
        desktop: 64,
    },
    disclaimerHeight: 24,
    colors: {
        teal: {
            300: '#78e4e8',
        },
        magenta: {
            200: '#f7b4df',
            400: '#ce3197',
        },
    },
    highlightedListing: {
        backgroundColor: '#f7b4df',
    },
    highlightedListingLightGray: {
        backgroundColor: '#f6f6fb',
    },
    selectedSection: {
        fill: '#f7b4df',
        stroke: '#ce3197',
        'stroke-width': '2px',
    },
    zoneHoveredSection: {
        stroke: '#ce3197',
        'stroke-width': '2px',
    },
} as const

export const IMAGES = {
    DEFAULT_HERO_MOBILE: '//a.vsstatic.com/mobile/app/generic.jpg',
    DEFAULT_HERO_DESKTOP: '//a.vsstatic.com/banner/event/category/generic.jpg',
    GIFT_CARDS_GIVE_TICKETS: '//a.vsstatic.com/company/gift-card/mobile-tickets.png',
    MAPS_NO_IMAGE_AVAILIBLE: '//vivid-assets.s3.amazonaws.com/seat_views/maps-no-image-available.png',
    REGION_HERO_MOBILE: '//vivid-assets.s3.amazonaws.com/mobile/region/regions-mobile.jpg',
    REGION_HERO_DESKTOP: '//vivid-assets.s3.amazonaws.com/banner/region/regions-desktop.jpg',
    REWARDS_HERO_DESKTOP: '//a.vsstatic.com/company/rewards/v3/rewards-page/hero-bg.png',
    REWARDS_HERO_MOBILE: '//a.vsstatic.com/company/rewards/v3/rewards-page/hero-bg-sm.png',
    REWARDS_TRIANGLE_ICON_LARGE: '//a.vsstatic.com/company/rewards/v3/rewards-page/rewards-triangle-2x.png',
    REWARDS_TRIANGLE_ICON_SMALL: '//a.vsstatic.com/company/rewards/v3/rewards-page/app-icon.png',
    REWARDS_GOOGLE_PLAY: 'https://media.vsstatic.com/image/upload/Product/assets/app-stores/google-play-store.svg',
    REWARDS_APP_STORE: 'https://media.vsstatic.com/image/upload/Product/assets/app-stores/app-store.svg',
    REWARDS_IPHONE: '//a.vsstatic.com/company/rewards/v3/rewards-page/iphone-rewards-2x.png',
    REWARDS_BUY_MORE_EARN_MORE_DESKTOP:
        'https://media.vsstatic.com/image/upload/v1671487213/football-desktop-2x_krxlxe.jpg',
    REWARDS_BUY_MORE_EARN_MORE_MOBILE:
        'https://media.vsstatic.com/image/upload/v1671487213/football-mobile-2x_dprdpa.jpg',

    REWARDS_NO_EXCLUSIONS_DESKTOP:
        'https://media.vsstatic.com/image/upload/v1671487304/concert-performer-desktop-2x_qddexe.jpg',
    REWARDS_NO_EXCLUSIONS_MOBILE:
        'https://media.vsstatic.com/image/upload/v1671487304/concert-performer-mobile-2x_exouaf.jpg',
    REWARDS_FREE_TO_JOIN_DESKTOP: 'https://media.vsstatic.com/image/upload/v1671487336/ballerina-desktop-2x_azakmb.jpg',
    REWARDS_FREE_TO_JOIN_MOBILE: 'https://media.vsstatic.com/image/upload/v1671487336/ballerina-mobile-2x_nj4nt5.jpg',
    SKYBOX_LOGO: '//a.vsstatic.com/company/our-platforms/skybox-logo.png',
    SKYBOX_COMPUTER: '//a.vsstatic.com/company/our-platforms/skybox-2x.png',

    SIZE: {
        MOBILE_HERO: 'MOBILEHERO',
    },
} as const

export const REGION_PAGE = {
    TITLE: 'Region Directory | Vivid Seats',
    DESCRIPTION:
        'Find event tickets by region quickly and conveniently at Vivid Seats. Featuring a full range of sports, concerts, and theater tickets for regions in the US and Canada.',
    URL: '/region',
} as const

export const REWARDS_PAGE = {
    TITLE: 'Vivid Seats Rewards – Vivid Seats',
} as const

export const SEARCH_PAGE = {
    TITLE: 'Search Results – Vivid Seats',
    HEADER_TITLE: 'Search Results',
} as const

export const PERFORMER_PAGE = {
    MAX_RELATED_ARTISTS: 10,
    MAX_RELATED_EVENTS: 6,
} as const

// Contact Us Pages
export const EXISTING_ORDERS_PAGE = {
    TITLE: 'Existing Orders | Vivid Seats',
} as const

export const SHOPPING_FOR_TICKETS_PAGE = {
    TITLE: 'Shopping for Tickets | Vivid Seats',
} as const

export const SELLING_TICKETS_PAGE = {
    TITLE: 'Selling Tickets | Vivid Seats',
} as const

export const METERS_PER_MILE = 1609 // Actually 1609.34
export const MAX_DRIVING_DISTANCE = 150 * METERS_PER_MILE
export const MAX_NEARBY_DISTANCE = 50 * METERS_PER_MILE
export const MAX_PRODUCTIONS_TO_SHOW = 50

export const VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT = 25
export const PRODUCTIONS_PAGE_SIZE = 100
export const REGIONS_PAGE_SIZE = 100
export const CATEGORY_VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT = 10
export const CATEGORY_ALL_PRODUCTION_LIST_PAGE_SIZE = PRODUCTIONS_PAGE_SIZE
export const CATEGORY_ALL_PRODUCTION_LIST_SORT_BY = 'DATE'
export const CATEGORY_POPULAR_PERFORMERS_PAGE_SIZE = 15
export const CATEGORY_POPULAR_PERFORMERS_SORT_BY = 'RANK'
export const SUB_CATEGORY_FEATURED_EVENTS_PAGE_SIZE = 50
export const SUB_CATEGORY_FEATURED_EVENTS_SORT_BY = 'RANK'
export const TOP_VENUES_SORT_BY = 'RANK'

export const LIMITED_INVENTORY_THRESHOLD = 100
export const PERCENT_REMAINING_LOWER_THRESHOLD = 2
export const PERCENT_REMAINING_UPPER_THRESHOLD = 5
export const MIN_CHARACTERS_FOR_SEARCH = 2
export const MAX_RESULTS_BY_CATEGORY_FOR_SEARCH = 3
export const MAX_RESULTS_BY_VENUE_FOR_SEARCH = 2
export const MAX_DEBOUNCE_WAIT = 250
export const MARGIN_TOP_SUGGESTIONS = 70
export const CENTER_SEARCH_SCROLL_THRESHOLD = 60
export const CENTER_SEARCH_SCROLL_THRESHOLD_DESKTOP = 80
export const TRENDING_CATEGORY_WIDTH_THRESHOLD = 1120
export const MAX_TOP_VENUES = 10
export const MAX_TOP_PICKS_CARDS = 16

export const PERFORMER_URGENCY_THRESHOLD = 500
export const PERFORMER_VELOCITY_THRESHOLD = 100

export const ACCOUNT_INFO_ENDPOINT = '/api/v1/account/info'
export const ORDER_DETAIL_ENDPOINT = '/api/v1/account/orders'

export const COGNITO_CREATE_ACCOUNT_ENDPOINT = '/api/v1/account/auth/create'
export const COGNITO_LOGOUT_ENDPOINT = '/api/v1/account/auth/logout'
export const COGNITO_LOGIN_ENDPOINT = '/api/v1/account/auth/login'
export const COGNITO_REFRESH_TOKEN_ENDPOINT = '/api/v1/account/auth/refresh'
export const COGNITO_FORGOT_PASSWORD_ENDPOINT = '/api/v1/account/auth/forgotPassword'
export const COGNITO_RESET_PASSWORD_ENDPOINT = '/api/v1/account/auth/resetPassword'
export const COGNITO_ACCOUNT_TAKEN_ENDPOINT = '/api/v1/account/auth/taken'
export const COGNITO_UPDATE_PASSWORD_ENDPOINT = '/api/v1/account/auth/updatePassword'
export const COGNITO_VALIDATE_RESET_PASSWORD_TOKEN_ENDPOINT = '/api/v1/account/auth/validateResetPasswordToken'
export const COGNITO_UPDATE_EMAIL_ENDPOINT = '/api/v1/account/auth/updateEmail'
export const COGNITO_SOCIAL_LOGIN_ENDPOINT = '/api/v1/account/auth/socialLogin'
export const COGNITO_SOCIAL_LOGIN_CALLBACK_ENDPOINT = '/api/v1/account/auth/socialLogin/callback'

export const CUSTOM_PAGES_ENDPOINT = '/api/v1/custom-pages'
export const MATCHUP_PAGES_ENDPOINT = '/api/v1/matchup-pages'
export const CATALOG_ASSET_ENDPOINT = '/api/v1/assets'
export const PRODUCTIONS_ENDPOINT = '/api/v1/productions'
export const PRODUCTIONS_TOTALS_ENDPOINT = '/api/v1/productions/totals'
export const ONSALE_PRESALE_ENDPOINT = '/api/v1/productions/onsalePresale'
export const PERFORMERS_ENDPOINT = '/api/v1/performers'
export const PERFORMERS_NAVIGATION_ENDPOINT = '/api/v1/performers/navigation'
export const RELATED_PERFORMERS_ENDPOINT = '/api/v1/performers/related'
export const RELATED_PRODUCTIONS_ENDPOINT = '/api/v1/productions/related'
export const SEARCH_SUGGESTION_ENDPOINT = '/api/v1/search-suggestions'
export const TRENDINGS_ENDPOINT = '/api/v1/trendings'
export const URGENCY_VELOCITY_ENDPOINT = '/api/v1/uv'

export const GEOLOCATION_ENDPOINT = '/api/v1/location'
export const ACCOUNT_LOCATION_ENDPOINT = `/api/v1/account/locations`
export const ACCOUNT_FAVORITES_ENDPOINT = `/api/v1/account/favorites`
export const ACCOUNT_ADDRESS_ENDPOINT = `/api/v1/account/addresses`
export const ACCOUNT_PAYMENT_METHODS_ENDPOINT = `/api/v1/account/payment-methods`
export const ACCOUNT_ORDER_ENDPOINT = `/api/v1/account/orders`
export const CMS_HOME_ENDPOINT = '/api/v1/cms/home'
export const CMS_PERFORMERS_ENDPOINT = '/api/v1/cms/performers'
export const LISTINGS_ENDPOINT = '/api/v1/listings'
export const LAYOUT_ENDPOINT = '/api/v1/layout'
export const REGIONS_ENDPOINT = '/api/v1/cms/regions'
export const VENUES_ENDPOINT = '/api/v1/venues'
export const RELATED_VENUES_ENDPOINT = `${VENUES_ENDPOINT}/related`
export const CATEGORIES_ENDPOINT = '/api/v1/categories'
export const STATIC_PAGES_ENDPOINT = '/api/v1/static-pages'
export const SITEMAP_PERFORMERS_ENDPOINT = '/api/v1/sitemap/performers'
export const SITEMAP_VENUES_ENDPOINT = '/api/v1/sitemap/venues'
export const SEARCH_REDIRECTS_ENDPOINT = '/api/v1/search-redirects'
export const FANLISTINGS_ENDPOINT = '/api/v1/account/listings'
export const SALES_ENDPOINT = '/api/v1/account/sales'
export const COUNTRY_CONFIG_ENDPOINT = '/api/v1/i18n/country-configs'
export const PROMO_BY_TRIGGER_ATTR_ENDPOINT = '/api/v1/promo/triggerAttr'
export const BRAZE_EMAIL_SUBSCRIPTION_STATE_ENDPOINT = '/api/v1/marketing/subscription'
export const MLB_EMAIL_SUBSCRIPTION_ENDPOINT = '/api/v1/checkout/mlb-subscribe'

export const CATALOG_ENDPOINTS = [
    CATALOG_ASSET_ENDPOINT,
    LISTINGS_ENDPOINT,
    LAYOUT_ENDPOINT,
    PERFORMERS_ENDPOINT,
    PERFORMERS_NAVIGATION_ENDPOINT,
    PRODUCTIONS_ENDPOINT,
    REGIONS_ENDPOINT,
    RELATED_PERFORMERS_ENDPOINT,
    RELATED_PRODUCTIONS_ENDPOINT,
    SEARCH_SUGGESTION_ENDPOINT,
    SITEMAP_PERFORMERS_ENDPOINT,
    SITEMAP_VENUES_ENDPOINT,
    VENUES_ENDPOINT,
]

export const PERFORMER_DEFAULT_SORT = 'RANK'

// Number of additional venues to show on on venue pages
export const NUM_VENUES_LINKS = 20

export const DEFAULT_CONTEXT_VALUES: { state: any; selectors: any; dispatch: any } = {
    state: {},
    selectors: {},
    dispatch: {},
}

export const MAP_VALUES = {
    MIN_SCALE: 1,
    STEP: 0.6,
} as const

export const MAX_PRODUCTIONS_DATE_SWITCHER = 250

/** Not used at the moment */
export const BUYER_GUARANTEE_DISCLAIMER_REGIONS = {
    NV: [31, 33],
    FL: [75, 78, 79, 95, 96],
    NY: [7, 108, 109, 110],
    UT: [34],
} as const

export const UTAH_PERFORMER_NAMES = ['Utah', 'BYU', 'Weber State', 'Real Monarchs', 'Beehive Classic', 'Salt Lake']
export const CURRENCY_DISCLAIMER_LINE1 = `Prices in the selected currency are for informational purposes only.`
export const CURRENCY_DISCLAIMER_LINE2 = `The currency you'll be charged in will be confirmed at checkout.`
export const SECONDARY_MARKETPLACE_DISCLAIMER_STATES = ['UT']
export const SECONDARY_MARKETPLACE_DISCLAIMER_TEXT =
    'Vivid Seats is a ticket resale marketplace and not the primary ticket seller. Prices may be higher or lower than face value.'

export const PAID_SEARCH_DISCLAIMER_TEXT = 'All resale ticket prices may be above or below face value.'
export const REWARDS_DISCLAIMER_TEXT =
    '*Earn a Reward Credit equal to the average price of the 10 tickets, excluding taxes, fees, and processing costs.'

// A TrustArc GTM script looks for this element id to create a link that opens a Cookie Preferences modal.
export const TRUSTARC_ELEMENT_ID = 'teconsent'

export const LEGAL_LINKS = [
    { label: 'Accessibility', to: PATHS.ACCESSIBILITY, showInProductionFooter: false },
    { label: 'Privacy Policy', to: PATHS.PRIVACY_POLICY, showInProductionFooter: true },
    { label: 'Do Not Sell or Share My Personal Information', to: PATHS.DO_NOT_SELL, showInProductionFooter: true },
    { label: 'Terms of Use', to: PATHS.TERMS_AND_CONDITIONS, showInProductionFooter: false },
    { label: '', to: '#', id: TRUSTARC_ELEMENT_ID, showInProductionFooter: true }, // Should be last, to avoid a visual gap in the footer in jurisdictions where the TrustArc modal is not enabled
] as const

export const VTRK_COOKIE_KEYWORD_REGEX = 'keywordId=([^|]+)'
export const VTRK_COOKIE_VCAMP_REGEX = 'v_camp=([^|]+)'
export const VTRK_COOKIE_VMED_REGEX = 'v_med=([^|]+)'
export const VTRK_COOKIE_VSRC_REGEX = 'v_src=([^|]+)'
export const VTRK_COOKIE_VTRM_REGEX = 'v_trm=([^|]+)'
export const VTRK_COOKIE_ADGROUP_REGEX = 'adgroup=([^|]+)'
export const VTRK_COOKIE_VKID_REGEX = 'v_kid=([^|]+)'
export const VTRK_COOKIE_TARGET_REGEX = 'target=([^|]+)'
export const VTRK_COOKIE_DEVICE_REGEX = 'device=([^|]+)'
export const VTRK_COOKIE_GCLID_REGEX = 'gclid=([^|]+)'
export const VTRK_COOKIE = 'vtrk'

export const PRICE_GROUP_COOKIES = ['pgm_p_wsuser', 'pgm_c_pga', 'pgm_c_wsuser', 'pgm_c_pgid', 'pgid']
export const GA_USER_ID_COOKIE = 'ga_id'
export const GUEST_EMAIL_COOKIE = 'guest_email'
export const CRM_ID_COOKIE = 'crm_id_2'
export const RETURNING_USER_COOKIE = 'returning_customer'
export const WSUSER_COOKIE = 'wsUser' // when a user comes in with a wsUser parameter, the session is tagged as that wsusr

export const PERMANENT_COOKIE_SECONDS = 157680000

export const REFRESH_TOKEN_IN_ADVANCE_SECONDS = 5 * 60 * 1000 // 5 minutes

export enum PERFORMER_IDS {
    TAYLOR_SWIFT = 12245, // TODO: WFO-638
    OASIS = 627,
    SUPERBOWL = 1124,
    SUPERBOWL_PARKING = 14865,
}

export enum CATEGORY_IDS {
    CONCERTS = 2,
    SPORTS = 3,
    THEATER = 4,
}

export type CATEGORY_ID = keyof typeof CATEGORY_IDS

export enum CONCERTS_SUB_CATEGORY_IDS {
    FESTIVALS = 63,
}

export enum SPORTS_SUB_CATEGORY_IDS {
    NFL_FOOTBALL = 1,
    MLB_BASEBALL = 2,
    NBA_BASKETBALL = 3,
    NHL_HOCKEY = 4,
    NCAA_FOOTBALL = 5,
    NCAA_BASKETBALL = 6,
    RUGBY = 30,
    SOCCER = 34,
    FOOTBALL = 37,
    BASEBALL = 38,
    HOCKEY = 39,
    BASKETBALL = 40,
    ARENA_FOOTBALL = 55,
    SOFTBALL = 76,
    MINOR_LEAGUE_BASEBALL = 77,
    COLLEGE_BASEBALL = 78,
    AAF = 81,
    WWE = 13,
    WNBA_BASKETBALL = 83,
    MLS_SOCCER = 87,
}

export const CONTENT_TAGS_TO_SEPARATE = {
    NFL_PRESEASON: 'nfl_preseason',
    NFL_FULL_SEASON: 'nfl_full_season',
    NFL_REGULAR_SEASON: 'nfl_regular_season',
}

export const CONTENT_TAGS_NFL_ORDER = [
    CONTENT_TAGS_TO_SEPARATE.NFL_REGULAR_SEASON,
    CONTENT_TAGS_TO_SEPARATE.NFL_PRESEASON,
    CONTENT_TAGS_TO_SEPARATE.NFL_FULL_SEASON,
]

export const CONTENT_TAGS_NFL_TITLES = {
    [CONTENT_TAGS_TO_SEPARATE.NFL_REGULAR_SEASON]: 'Schedule',
    [CONTENT_TAGS_TO_SEPARATE.NFL_PRESEASON]: 'Preseason Schedule',
    [CONTENT_TAGS_TO_SEPARATE.NFL_FULL_SEASON]: 'Full Season Package',
}

export const MONDAY_NIGHT_FOOTBALL_TAG = 'monday_night_football'
export const THURSDAY_NIGHT_FOOTBALL_TAG = 'thursday_night_football'

export const CATEGORY_NAMES: CategoryIdMap<string> = {
    [CATEGORY_IDS.CONCERTS]: 'Concerts',
    [CATEGORY_IDS.SPORTS]: 'Sports',
    [CATEGORY_IDS.THEATER]: 'Theater',
}

export const CATEGORY_WEBPATHS: CategoryIdMap<string> = {
    [CATEGORY_IDS.CONCERTS]: '/concerts',
    [CATEGORY_IDS.SPORTS]: '/sports',
    [CATEGORY_IDS.THEATER]: '/theater',
}

export const CATEGORY_PRODUCTIONS_TITLES: CategoryIdMap<string> = {
    [CATEGORY_IDS.CONCERTS]: 'Upcoming Shows',
    [CATEGORY_IDS.SPORTS]: 'Upcoming Sporting Events',
    [CATEGORY_IDS.THEATER]: 'Upcoming Shows',
}

// Map of which mobile images should show short 16:9 performer cards
export const IS_SHORT_HEIGHT_IMAGE: CategoryIdMap<boolean> = {
    [CATEGORY_IDS.CONCERTS]: false,
    [CATEGORY_IDS.SPORTS]: true,
    [CATEGORY_IDS.THEATER]: false,
}

export const ALL_CATEGORY_IDS: CATEGORY_IDS[] = [CATEGORY_IDS.CONCERTS, CATEGORY_IDS.SPORTS, CATEGORY_IDS.THEATER]

export const CATEGORY_ANALYTICS_ID_MAP: Record<CATEGORY_IDS, string> = {
    [CATEGORY_IDS.CONCERTS]: AnalyticsIds.ConcertsCategory,
    [CATEGORY_IDS.SPORTS]: AnalyticsIds.SportsCategory,
    [CATEGORY_IDS.THEATER]: AnalyticsIds.TheaterCategory,
}

export const CATEGORY_PAGE_TYPE_MAP: Record<CATEGORY_IDS, PageType> = {
    [CATEGORY_IDS.CONCERTS]: PageType.ConcertsCategory,
    [CATEGORY_IDS.SPORTS]: PageType.SportsCategory,
    [CATEGORY_IDS.THEATER]: PageType.TheaterCategory,
}

// So we can suppress debug logging in test and production
export const DEBUG = process.env.DEBUG === 'true'
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_SENTRY_ENV === 'production'
export const AUTH_TOKEN_COOKIE = 'at'
export const CURRENT_STEP_SESSION_STORAGE_KEY = 'currentStep'
export const MINIMUM_CREDIT_CARD_LENGTH = 13
export const MINIMUM_PASSWORD_LENGTH = 6

// Countdown
export const ONE_SECOND = 1000
export const DEFAULT_COUNTDOWN_TIME = ONE_SECOND * 600
export const URGENCY_TIMER_KEY = 'checkoutCountdown'
export const DEFAULT_REFETCH_INTERVAL = 5 * 60 * ONE_SECOND

// Endpoints
export const BRAINTREE_TOKEN_ENDPOINT = '/api/v1/checkout/braintree/client_token'
export const QUOTE_ENDPOINT = '/api/v1/checkout/quote'
export const LOYALTY_QUOTE_ENDPOINT = '/api/v1/checkout/loyalty/quote'
export const LOGIN_ENDPOINT = '/api/v1/checkout/auth/login'
export const FACEBOOK_LOGIN_ENDPOINT = '/api/v1/checkout/auth/fb'
export const AUTH_TOKEN_ENDPOINT = '/api/v1/checkout/auth/token'
export const EMAIL_VERIFY_ENDPOINT = '/api/v1/checkout/auth/verify-email'
export const ORDER_ENDPOINT = '/api/v1/checkout/order'
export const USER_ENDPOINT = '/api/v1/checkout/user'
export const ACCOUNT_ORDERS_ENDPOINT = '/api/v1/account/orders'
export const PARKING_ENDPOINT = '/api/v1/checkout/parking'
export const TRUSTPILOT_ENDPOINT = '/api/v1/trustpilot/generateServiceReviewLink'
export const KLARNA_ENDPOINT = '/api/v1/checkout/klarna'
export const KLARNA_MIN_ORDER_VALUE = 35
export const KLARNA_MAX_ORDER_VALUE = 1000

export const UNKNOWN_ERROR_STRING = 'Please check your billing information and payment details before trying again'
export const TICKET_PRICE_CHANGED_ERROR_STRING =
    "We're sorry, but the seller has updated the price of these tickets. Vivid Seats is a live marketplace and sellers can update their prices at any time. Please verify your total and resubmit your order."

export enum Step {
    LOGIN = 1,
    DELIVERY = 2,
    BILLING = 3,
    PLACE_ORDER = 4,
    ORDER_COMPLETE = 5,
}

export const IS_COUNTDOWN_ENABLED = {
    [Step.LOGIN]: false,
    [Step.DELIVERY]: true,
    [Step.BILLING]: true,
    [Step.PLACE_ORDER]: true,
    [Step.ORDER_COMPLETE]: false,
}

export const SHOW_PRICE_BREAKDOWN = {
    [Step.LOGIN]: false,
    [Step.DELIVERY]: false,
    [Step.BILLING]: false,
    [Step.PLACE_ORDER]: true,
    [Step.ORDER_COMPLETE]: false,
}
// This controls which steps the pricing breakdown shows on for NY AIP
export const SHOW_PRICE_BREAKDOWN_AIP: Record<Step, boolean> = {
    [Step.LOGIN]: false,
    [Step.DELIVERY]: true,
    [Step.BILLING]: true,
    [Step.PLACE_ORDER]: true,
    [Step.ORDER_COMPLETE]: false,
}

export const SHOW_MOBILE_TICKET_DETAILS: Record<Step, boolean> = {
    [Step.LOGIN]: false,
    [Step.DELIVERY]: false,
    [Step.BILLING]: false,
    [Step.PLACE_ORDER]: true,
    [Step.ORDER_COMPLETE]: false,
}

export const DEFAULT_DATA_FIELD: any = {
    name: '',
    value: '',
    required: false,
    disabled: false,
}

export const NY_FREE_DELIVERY_METHOD_IDS = [
    11, // EMAIL DELIVERY
    18, // ELECTRONIC - INSTANT DOWNLOAD
    28, // MOBILE E-TICKET
    29, // MOBILE E-TICKET INSTANT
    40, // MOBILE APP
    27, // FLASH SEATS
    42, // INSTANT FLASH SHEETS
    41, // INSTANT TRANSFER
    43, // TEXT TRANSFER
    26, // TICKETMASTER TRANSFER
]

// Countries
export const US = 'US'
export const CA = 'CA'
export const UK = 'GB'

export const COUNTRIES: Option<string>[] = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia, Plurinational State of' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CV', label: 'Cabo Verde' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: CA, label: 'Canada' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, Democratic Republic of the' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czechia' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'SZ', label: 'Eswatini' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'VA', label: 'Holy See' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran, Islamic Republic of' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: "Korea, Democratic People's Republic of" },
    { value: 'KR', label: 'Korea, Republic of' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: "Lao People's Democratic Republic" },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States of' },
    { value: 'MD', label: 'Moldova, Republic of' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine, State of' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'RE', label: 'Réunion' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin (French part)' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan, Province of China' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania, United Republic of' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: US, label: 'United States' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VG', label: 'Virgin Islands, British' },
    { value: 'VI', label: 'Virgin Islands, U.S.' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
]

export const SUBCATEGORY_RANKINGS: Record<string, number> = {
    Rock: 1,
    Pop: 2,
    'Country and Folk': 3,
    'Rap/Hip Hop': 4,
    'Latin Music': 5,
    'Adult Contemporary': 6,
    'R&B': 7,
    Alternative: 8,
    'Dance/Electronica': 9,
    'Hard Rock': 11,
    'World Music': 12,
    Classical: 13,
    'Blues and Jazz': 14,
    'K-pop': 15,
    Reggae: 16,
    'New Age': 18,
}

export const COUNTRIES_WITH_BLANK: Option<string>[] = [
    { value: '', label: 'Select your country', dataTestId: 'blank-country-option' },
    ...COUNTRIES,
]

export const STATES: Option<string>[] = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'Washington, D.C.' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
]

export const STATES_WITH_BLANK: Option<string>[] = [
    { value: '', label: 'Select your state', dataTestId: 'blank-state-option' },
    ...STATES,
]

export const NO_STATE: Option<string>[] = [
    { value: '', label: 'Select your state', dataTestId: 'blank-state-option' },
    { value: 'N/A', label: 'No State', dataTestId: 'no-state-option' },
]

export const PROVINCES: Option<string>[] = [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon Territory' },
]

export const PROVINCES_WITH_BLANK: Option<string>[] = [
    { value: '', label: 'Select your province', dataTestId: 'blank-province-option' },
    ...PROVINCES,
]

export enum PaymentMethod {
    CREDIT_CARD = 'CREDIT_CARD',
    PAYPAL = 'PAYPAL',
    PAYPAL_PAY_LATER = 'PAYPAL_PAY_LATER',
    GIFT_CARD = 'GIFT_CARD',
    APPLE_PAY = 'APPLE_PAY',
    KLARNA = 'KLARNA',
    DEFAULT = '',
}

export const PAYMENT_METHODS: string[] = Object.values(PaymentMethod)

export const DAYJS_FORMAT = 'dddd, MMM DD, YYYY h:mm a'

export enum BraintreeAddressType {
    BILLING = 'billingAddress',
    SHIPPING = 'shippingAddress',
}

export enum ApplePayCardTypes {
    Visa = 'Visa',
    Mastercard = 'Mastercard',
    Discover = 'Discover',
    Amex = 'Amex',
}

export enum ApplePayTestingNonces {
    Visa = 'fake-apple-pay-visa-nonce',
    Mastercard = 'fake-apple-pay-mastercard-nonce',
    Discover = 'fake-apple-pay-discover-nonce',
    Amex = 'fake-apple-pay-amex-nonce',
}
export const UTM_PROMO = 'utm_promo'
export const COMPANY_DISPLAY_NAME = 'Vivid Seats LLC'
export enum ClientVersions {
    default = 'web:checkout_2.0',
    applePay = 'web:mobile:applepay',
}

export enum InsuranceOptions {
    Option0 = 'insuranceOption0',
    Option1 = 'insuranceOption1',
}

export enum AnalyticEvents {
    AddToCart = 'add_to_cart',
    AddShippingInfo = 'add_shipping_info',
    AddPaymentInfo = 'add_payment_info',
    BeginCheckout = 'begin_checkout',
    CheckoutLogin = 'checkout_login',
    Purchase = 'purchase',
    PurchaseAuthorization = 'purchase_authorization',
    PurchaseError = 'purchase_error',
    FireFluent = 'fire_trustpilot_and_fluent',
    SelectInsurance = 'select_insurance',
    SignUp = 'sign_up',
}

// @see https://vividseats.atlassian.net/wiki/spaces/QAE/pages/2992111686/Delivery+Methods+-+How+to+set+up+listings+to+see+specific+delivery+methods
export enum DeliveryMethods {
    SpecialDelivery = 8,
    EmailDelivery = 11,
    InternationalSpecialDelivery = 12,
    InstantDownload = 18,
    UPSBasic = 19,
    UPSOvernight = 20,
    UPSCanadaBasic = 21,
    UPSCanadaOvernight = 22,
    UPSUSCanada = 23,
    UPSSaturday = 24,
    UPSInternational = 25,
    ElectronicTransfer = 26,
    FlashSeats = 27,
    MobileDelivery = 28,
    MobileDeliveryInstantDownload = 29,
    PrepaidUPSBasic = 31,
    PrepaidUPSOvernight = 32,
    PrepaidUPSCanadaBasic = 33,
    PrepaidUPSCanadaOvernight = 34,
    PrepaidUPSUSCanada = 35,
    PrepaidUPSSaturday = 36,
    PrepaidUPSInternational = 37,
    PaperlessWalkIn = 38,
    PrepaidSpecialDelivery = 39,
    MobileApp = 40,
    InstantTransfer = 41,
    InstantFlashSeats = 42,
    TextTransfer = 43,
}

// Copied from https://github.com/VividSeats/vivid-web/blob/a1f21743ea2a0821766b0567a3ef80a5500f2408/src/main/es6/constants/disclosureConstants.js#L1
export const DISCLOSURE_CONSTANTS = {
    EMAIL_DELIVERY: {
        TITLE: 'E-mail Delivery',
        DESCRIPTION: () => (
            <span data-testid="email-delivery-description">
                You will receive an email with download instructions when your tickets are available.
            </span>
        ),
    },
    INSTANT_DOWNLOAD: {
        TITLE: 'Instant Download',
        DESCRIPTION: () => (
            <span data-testid="instant-download-description">
                Your tickets will be available for download immediately after your order is confirmed.
            </span>
        ),
    },
    MOBILE_TICKETS: {
        TITLE: 'Mobile Tickets',
        DESCRIPTION: () => (
            <span data-testid="mobile-tickets-description">
                When the seller transfers the tickets to you, you will receive an email instructing you how to claim the
                tickets on your phone. Please note that you will need to use an iOS or Android mobile device to gain
                entry to your event.
            </span>
        ),
    },
    MOBILE_TICKETS_ALTERNATE: {
        TITLE: 'Mobile Tickets',
        DESCRIPTION: () => (
            <>
                <span data-testid="mobile-tickets-alternate-description">
                    Your ticket will be delivered through the Vivid Seats mobile app -
                </span>
                <strong>
                    {' '}
                    You must use the app for entry. Your barcode will be available immediately after purchase.
                </strong>
            </>
        ),
    },
    MOBILE_APP: {
        TITLE: 'Vivid Seats Mobile App',
        DESCRIPTION: () => (
            <>
                <span data-testid="mobile-app-description">
                    When the seller transfers your tickets, access them right in the Vivid Seats app. Download for event
                    updates, order details and more.
                </span>
            </>
        ),
    },
    PREFERRED_SELLER: {
        TITLE: 'Preferred Seller',
        DESCRIPTION: () => (
            <span data-testid="preferred-seller-description">
                Preferred Sellers are longstanding partners with Vivid Seats. They have earned the Preferred Seller
                status because of their industry experience and because they continue to provide Vivid Seats with
                exceptional inventory and service.
            </span>
        ),
    },
    BUYER_GUARANTEE: {
        TITLE: 'Buyer Guarantee',
        DESCRIPTION: () => (
            <span data-testid="buyer-guarantee-description">
                Valid tickets delivered in time for the event or your money back.
            </span>
        ),
    },
    VALUE_SCORE: {
        TITLE: 'Value Score',
        DESCRIPTION: () => (
            <span data-testid="value-score-description">
                Value Score identifies tickets with the best value for your event based on price and seat location in
                the venue.
            </span>
        ),
    },
    INSTANT_DELIVERY: {
        TITLE: 'Instant Delivery',
        DESCRIPTION: () => (
            <span data-testid="instant-delivery-description">
                Your tickets will be available within minutes of placing your order.
            </span>
        ),
    },
    ELECTRONIC_TICKET: {
        TITLE: 'E-ticket',
        DESCRIPTION: () => (
            <span data-testid="eticket-description">
                E-tickets are electronic tickets that may be downloaded to your computer before the event. You will
                receive an email with download instructions when they are made available.
            </span>
        ),
    },
}

export enum CountriesWithRegulations {
    Canada = 'CA',
}

export enum StatesWithRegulations {
    UT = 'UT',
    NY = 'NY',
    MD = 'MD',
}

export const GREAT_DEAL_VS_THRESHOLD = 6.0

export const FDI_THRESHOLD = 5
export const PDI_THRESHOLD = 0.05

export const RECENTLY_VIEWED_EXPIRATION_DURATION = 30 as const // days
export const RECENTLY_VIEWED_LOCAL_STORAGE_KEY = 'recentlyViewedState' as const

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export type Weekday = (typeof WEEKDAYS)[number]

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
] as const

export type Month = (typeof MONTHS)[number]

export enum SOCIAL_PROVIDER {
    APPLE = 'APPLE',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE',
}

// TODO: make this an environment variable once we have different buckets for stage and prod
export const MAP_ENDPOINT = 'https://d3pz2kc9e7xd94.cloudfront.net/'

export const MLB_PRIVACY_POLICY = 'https://www.mlb.com/official-information/privacy-policy'

// API Factory Defaults
export const DEFAULT_REQUEST_TIMEOUT = ONE_SECOND * 10
export const REQUEST_RETRY_LIMIT = 2
