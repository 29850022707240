import type { $FC } from 'react'
import React, { createContext, useEffect, useMemo, useState } from 'react'

import type { CookieSetOptions } from 'react-cookie'
import { Cookies } from 'react-cookie'

import { serverSideQuery, useQuery } from '@/api'
import { COUNTRY_CONFIG_ENDPOINT, COOKIES } from '@/constants'
import type { CountryConfig } from '@/types/app-types'
import { trackDisplayCurrencyEvent as trackDisplayCurrencyEventGA4 } from '@/utils/analytics'
import { trackDisplayCurrency, trackDisplayCurrencyEvent } from '@/utils/analytics/ga4'
import { DisplayCurrencyEventEnum } from '@/utils/analytics/types'
import { getCurrencySymbol } from '@/utils/i18n'

import { AUTO_DETECTED, DEFAULT_CURRENCY, USER_DEFINED } from './constants'
import { getContactSettingsSelector, getDefaultCurrencySelector, getFlagSelector } from './selectors'
import type {
    InternationalizationContextType,
    InternationalizationDispatchType,
    InternationalizationSelectorType,
    InternationalizationStateType,
    SelectedCurrency,
} from './types'

const INITIAL_CURRENCY = { currency: '', method: AUTO_DETECTED }
const preferredCurrencyCookieOption: CookieSetOptions = { path: '/' }
const cookies = new Cookies()

export interface InitialProps {
    readonly storedCurrency?: SelectedCurrency
    readonly data?: CountryConfig
}

export interface InternationalizationProviderProps {
    initialProps?: InitialProps
}

const defaultState: InternationalizationStateType = {
    data: {
        availableCurrencies: [],
        countries: [],
        marketingOptIn: false,
    },
}

const defaultSelector: InternationalizationSelectorType = {
    flag: undefined,
    selectedCurrency: '',
    selectedCurrencySymbol: '',
    defaultCurrency: '',
    marketingOptIn: false,
    contactUsSettings: undefined,
}

const defaultDispatch: InternationalizationDispatchType = {
    selectCurrency: () => null,
}

const InternationalizationContext = createContext<InternationalizationContextType>({
    state: defaultState,
    selectors: defaultSelector,
    dispatch: defaultDispatch,
})
const { Provider } = InternationalizationContext

export const InternationalizationProvider: $FC<InternationalizationProviderProps> = ({ children, initialProps }) => {
    const storedCurrency = initialProps?.storedCurrency
    const defaultCurrency = storedCurrency || INITIAL_CURRENCY
    const [selectedCurrency, setSelectedCurrency] = useState<SelectedCurrency>(defaultCurrency)
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState<string>(
        getCurrencySymbol(defaultCurrency.currency),
    )

    const { fetching, data, error } = useQuery<CountryConfig>({
        endpoint: COUNTRY_CONFIG_ENDPOINT,
        initialData: initialProps?.data,
    })

    useEffect(() => {
        if (fetching) return
        const detectedCurrency =
            data?.countries?.find((country) => country.isDefault)?.currencyDefault || DEFAULT_CURRENCY
        const method = storedCurrency?.method || AUTO_DETECTED
        const currency = storedCurrency?.currency || detectedCurrency
        trackDisplayCurrency({ currency, method })
        trackDisplayCurrencyEvent({ event_type: DisplayCurrencyEventEnum.INIT, display_currency: currency })
    }, [data, fetching, storedCurrency])

    const selectCurrency = (currency: string) => {
        setSelectedCurrency({ currency, method: USER_DEFINED })
        setSelectedCurrencySymbol(getCurrencySymbol(currency))
        cookies.set(
            COOKIES.PREFERRED_CURRENCY,
            JSON.stringify({ currency, method: USER_DEFINED }),
            preferredCurrencyCookieOption,
        )
        trackDisplayCurrencyEventGA4({ event_type: DisplayCurrencyEventEnum.TOGGLE, display_currency: currency })
    }

    const contextValue = useMemo(() => {
        const state: InternationalizationStateType = {
            fetching,
            error,
            data,
        }
        const selectors: InternationalizationSelectorType = {
            flag: getFlagSelector(state, selectedCurrency?.currency || ''),
            defaultCurrency: getDefaultCurrencySelector(state),
            selectedCurrency: selectedCurrency?.currency || getDefaultCurrencySelector(state),
            selectedCurrencySymbol,
            marketingOptIn: data?.marketingOptIn || false,
            contactUsSettings: getContactSettingsSelector(state),
        }

        const dispatch: InternationalizationDispatchType = {
            selectCurrency,
        }

        return { state, selectors, dispatch }
    }, [data, error, fetching, selectedCurrency, selectedCurrencySymbol])

    return <Provider value={contextValue}>{children}</Provider>
}

/**
 * In production, Cloudflare provides the client IP in a `cf-connecting-ip` header.
 * To get the user's location in server-side render, include a `clientIp` param,
 * like this:
 *
 * ```
 * { clientIp: context.req.headers['cf-connecting-ip'] }
 * ```
 *
 * **NOTE**: Locally and in stage, we'll see odd behavior: Cloudflare isn't implemented,
 * so this header is undefined. As a result, Hermes will return a default location,
 * in the Chicago area.
 */

export const serverSideRead = serverSideQuery<CountryConfig>(COUNTRY_CONFIG_ENDPOINT)

export default InternationalizationContext
