import type { UseQueryHookParams } from '@/api/use-query/hooks'
import type { SectionRowsDataType } from '@/components/pages/production/components/context/types'
import type { CATEGORY_IDS } from '@/constants'
import type { SelectedCurrency } from '@/context/internationalization/types'

export const PROMO_CHARACTERS_THRESHOLD = 16

export interface AuthCookie {
    token: string
    accountId: number
    refreshToken: string
    tokenExpiresAt: number
}

export type AUTH_HEADER_VALUES = {
    accountId: string
    authToken: string
    authCookie: string
    refreshToken: string
}

export enum AllInPricingState {
    NEW_YORK = 'ny',
    TENNESSEE = 'tn',
    CONNECTICUT = 'ct',
}

export enum EMAIL_SUBSCRIPTION_STATE {
    OPTED_IN = 'opted_in',
    SUBSCRIBED = 'subscribed',
    UNSUBSCRIBED = 'unsubscribed',
}

export interface AllInPricingStateConfig {
    state: AllInPricingState
    enabled: boolean | undefined
}

export enum CreditCardType {
    AmericanExpress = 'American Express',
    Amex = 'Amex', // TODO: Amex is the same as American Express so should be consolidated
    Discover = 'Discover',
    MasterCard = 'MasterCard',
    Visa = 'Visa',
    DinersClub = 'Diners Club',
}

/** Properties that can be overridden for a specific category */
export interface RegionOverrides {
    title?: string
    subtitle?: string
    meta?: Metadata
}
export interface Region extends RegionOverrides {
    id: number
    name: string
    listTitle: string
    title?: string
    subtitle?: string
    imageUrl?: string
    latitude: number
    longitude: number
    radius: number
    stateAbbr: string
    countryAbbr: string
    article?: string
    weight: number
    webPath: string
    meta?: Metadata
    concertsOverrides?: RegionOverrides
    sportsOverrides?: RegionOverrides
    theaterOverrides?: RegionOverrides
}

export interface GenericResultType<TItem> {
    page: number
    total: number
    numberOfPages: number
    items: TItem[]
}

export interface CardWithImageGroup {
    title: string
    subtitle?: string
    filterParameters: Record<string, unknown>
    ctaTitle?: string
    ctaLink?: string
}

export interface CardWithImageGroupsData {
    title: string
    subtitle?: string
    cta?: {
        title: string
        link: string
    } | null
    productions: Production[]
}

interface CmsMetadata {
    title?: string
    description?: string
    keywords?: string
}

export interface CatalogCategory {
    id: number
    name: string
    organicUrl: string
    subCategories?: CatalogSubCategory[]
    master?: boolean
}

export interface CmsCategory {
    id: CATEGORY_IDS
    /** Alias for long name */
    name: string
    shortName: string
    longName: string
    webPath: string
    mobileImageUrl?: string
    desktopImageUrl?: string
    externalImageFilePath?: string
    article?: string
    meta: CmsMetadata
}

export interface CatalogSubCategory {
    id: number
    name: string
    organicUrl: string
}

export interface DynamicDateRange {
    id?: number
    dynamicDateRange:
        | 'today'
        | 'thisWeek'
        | 'thisWeekend'
        | 'thisMonth'
        | 'nextMonth'
        | 'nextYear'
        | 'customNumberOfDays'
}

export interface CmsCustomPage {
    id: number
    slug: string
    title: string
    subtitle?: string
    content: string
    externalImageFilePath?: string
    article_template?: string
    querystring?: string
    dynamicDateRange?: DynamicDateRange
    customNumberOfDays?: number
    latLong?: string
    radius?: number
    category: CatalogCategory
    categoryId: number
    subCategoryId: number
    performerId?: number
    venueId?: number
    maxPrice?: number
    legacyTemplateId?: number
    created_at: string
    updated_at: string
    meta: CmsMetadata
    linkGroup: FixedLinkGroup[] | null
}
export interface CmsMatchupPage {
    slug: string
    title: string
    longName?: string
    shortName?: string
    article?: string
    categoryId: number
    performerOne: number
    performerTwo: number
    mobileImageUrl?: string
    desktopImageUrl?: string
    externalImageFilePath?: string
    meta: CmsMetadata
    linkGroups: FixedLinkGroup | null
}

export interface CmsSearchRedirect {
    searchTerm: string
    redirectUrl: string
}

export interface CmsSubCategory {
    id: number
    categoryId: CATEGORY_IDS
    /** Alias for long name */
    name: string
    shortName: string
    longName: string
    webPath: string
    mobileImageUrl?: string
    desktopImageUrl?: string
    externalImageFilePath?: string
    linkGroups: (FixedLinkGroup | DynamicLinkGroup)[]
    cardWithImageGroups: CardWithImageGroup[]
    article?: string
    meta: CmsMetadata
}

export interface CmsStaticPage {
    slug: string
    title: string
    subtitle?: string
    content: string
    meta: CmsMetadata
    externalImageFilePath?: string
    updated_at: string
}

export interface DynamicLinkGroup {
    type: 'vivid.dynamic-link-group'
    title: string
    filterParameters: Record<string, unknown>
}

export interface FestivalHeadliner {
    performer: Performer
    festivalDate: string[]
    productions: Production[]
}
export interface FestivalLineup {
    performers: Performer[]
    date: string
    href?: string
    location?: string
    mobileLocation?: string
}

export interface FixedLinkGroup {
    type: 'vivid.fixed-link-group'
    title: string
    links: FixedLink[]
}

export interface FixedLink {
    label: string
    url: string
    id: number
}

export interface LinkGroupPerformerData {
    title: string
    links: PerformerLink[]
}

export interface Performer {
    id: number
    name: string
    category: CatalogCategory
    organicUrl?: string
    productionCount?: number
    master?: boolean
    tourName?: string
    title?: string
    subtitle?: string
    meta?: CmsMetadata
    article?: string
    webPath?: string
    parkingId?: number
    redirect_url?: string | null
    revenueRank?: number
    allTimeRevenueRank?: number
    exclusiveWsUserId?: number
    priority?: number
    urlName?: string
    nickname?: string
}

export interface PerformerLink {
    label: string
    url: string
    id: number
}

export interface Venue {
    id: number
    name: string
    address1?: string
    address2?: string
    city: string
    state: string
    postalCode?: string
    phone?: string
    countryCode: string
    regionId?: number
    timezone: string
    organicUrl?: string
    parkingVenueId?: number
    parkingImageUrl?: string
    latitude?: number
    longitude?: number
    productionCount?: number
    webPath?: string
    imageUrl?: string
    article?: string
    exclusiveUserId?: number
    revenueRank?: number
    exclusiveWsUserId?: number
    capacity?: number
}

export interface Trending {
    term: string
    url: string
    icon: string
}

export interface VenueInNewYork extends Venue {
    readonly state: 'NY'
}

export interface VenueInCanada extends Venue {
    readonly countryCode: 'CA'
}

export interface VenueInUK extends Venue {
    readonly countryCode: 'GB'
}

export interface VenueInQuebec extends Venue {
    readonly state: 'QC'
}

export interface SitemapPerformer {
    id: number
    name: string
    webPath?: string
}

export type SitemapPerformers = SitemapPerformer[]

export interface SitemapVenue {
    id: number
    name: string
    webPath?: string
}

export type SitemapVenues = SitemapVenue[]

export interface ContentTags {
    label: string
    displayName: string
    searchPhrase: string
    source: string
}

export interface Production {
    id: number
    localDate: string
    utcDate: string
    name: string
    venue: Venue
    minPrice: number
    maxPrice: number
    listingCount: number
    ticketCount: number
    categoryId: number
    subCategoryId: number
    dateTbd: boolean
    timeTbd: boolean
    ifNecessary: boolean
    organicUrl?: string
    performers: Performer[]
    webPath?: string
    distance?: number
    hidden: boolean
    similarProductionCount?: number
    localPrices?: {
        minPrice: number
        maxPrice: number
    }
    contentTags?: ContentTags[]
    isDoaActive?: boolean
    avgPrice?: number
    medianPrice?: number
    exclusiveWsUserId?: number
    primaryIntegrated?: boolean
    productionDelayType?: string
    seatRestrictions?: string | null
    onsaleDate?: string
    presale1Date?: string
    presale2Date?: string
}

export interface ProductionInNewYork extends Production {
    readonly venue: VenueInNewYork
}

export interface ProductionInCanada extends Production {
    readonly venue: VenueInCanada
}

export interface ProductionInUK extends Production {
    readonly venue: VenueInUK
}

export interface ProductionInQuebec extends Production {
    readonly venue: VenueInQuebec
}

export type ProductionPage = GenericResultType<Production>

export type TrailingSlashBehavior = 'always' | 'never' | 'no-change'

export interface Metadata {
    description?: string | null
    image?: string | null
    keywords?: string | null
    title?: string | null
    url?: string | null
    canonicalEnabled?: boolean
    trailingSlashBehavior?: TrailingSlashBehavior
    page_title?: string
    url_path?: string
    page_type?: string
}

export interface UserData {
    isAuthenticated?: boolean
    accountId?: string
    regionId?: number
    regionName?: string
    userAgent?: string
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
    utmTerm?: string
    utmAdgroup?: string
    utmTarget?: string
    utmContent?: string
    utmPromo?: string
    isDesktop?: boolean
    priceGroupId?: string
}

export interface CatalogImageAsset {
    dataModelName: 'CATEGORY' | 'SUBCATEGORY' | 'PERFORMER' | 'EVENT' | 'VENUE' | 'EXTERNAL' | string
    path: string
    size?: 'THUMBNAIL' | 'MOBILEHERO' | 'DESKTOPHERO' | string
}

export interface CatalogAssetRelatedResource {
    id: number
    type: 'SUBCATEGORY' | string
    images: Array<CatalogImageAsset>
}

export interface CatalogAsset {
    id: number
    type: string
    images: Array<CatalogImageAsset>
    relatedResources?: Array<CatalogAssetRelatedResource>
}

export interface DeliveryMethod {
    icon: 'ticket-pick-up' | 'ticket-transfer' | 'ticket-print' | 'ticket-truck' | 'ticket-mobile' | string
    name: string
    description: string
    aipShouldIncludeDelivery?: boolean
}

export enum BadgeCategories {
    TicketAttribute = 'TICKET_ATTRIBUTE',
    Scarcity = 'SCARCITY',
    Price = 'PRICE',
    Urgency = 'URGENCY',
}

export enum BadgeTitles {
    BrokerSale = 'SALE!',
    FrontOfSection = 'Front of Section',
    LastTicketInSection = 'Last Ticket in Section',
    LowestPriceInSection = 'Lowest Price in Section',
    LowPriceInSection = 'Low Price in Section',
    BestSellingSection = 'Best Selling Section',
    SectionSellingFast = 'Section Selling Fast',
}

export interface ListingBadge {
    category: BadgeCategories
    title: BadgeTitles
}

export interface ComposedListing {
    id: string
    inGroup?: boolean
    isGroup?: boolean
    price: number
    isGreatDeal: boolean
    isPreferredSeller: boolean
    isInstantDelivery: boolean
    isZoneSeating: boolean
    quantityRangeText: string
    quantityOptionsList: number[]
    section: string
    sectionLong: string
    sectionId: string
    groupId: string
    row: string
    notes: string
    defaultQuantity: number
    deliveryMethod?: DeliveryMethod
    faceValue: number
    allInPrice?: number
    stockTypeId: string
    dealScore?: number
    sectionColor?: string
    isRecentlyViewed?: boolean
    mbi?: string
    availableRows?: string[]
    availableRowsData?: SectionRowsDataType
    shouldUseLocalPrice?: boolean
    badges: ListingBadge[]
    tags?: string[]
    isVatAddedToAip?: boolean
    /** broker discount */
    isDiscounted?: boolean
    /** percent discount */
    percentageDiscount?: string
    /** fixed discount */
    fixedDiscount?: string
    qtySplitValue?: string
}

export interface ListingsGroup extends ComposedListing {
    isGroup: boolean
    // bestListingsByQuantity is an object with keys representing quantity and
    // values of the lowest priced listing in the group with that quantity available.
    // The 0 key is for the cheapest listing regardless of quantity.
    bestListingsByQuantity: Record<number, ComposedListing>
}

export interface ProductionDetail extends Production {
    eventId?: number
    assets: CatalogAsset[]
    singleProduction?: boolean
    formattedDate: {
        date: string
        time?: string
    }
    productionsCount: number
    article?: string
    isDoaActive?: boolean
    seatRestrictions?: string
}

export interface UserLocation {
    latitude: number
    longitude: number
    region: string
    city: string
    countryName: string
    countryCode: string
    method?: string
}

export interface UserCurrency {
    currency: string
    method: string
}

export type DefaultRequirement = {
    contactPageSettings: ContactUsSettings | undefined
}

export type ContactUsSettings = {
    existingOrdersTollFreeNumber: string
    shoppingTicketsTollFreeNumber: string
    sellingTicketsTollFreeNumber: string
    operationHours: string
    buyerGuarantee: string
    showTopFaqs: boolean
    liveChatUrl: string
    browseFaqsUrl: string
    showInternationalCallsPhoneNumber: boolean
    canFansSellTickets: boolean
}

export type Country = {
    countryCode: string
    isDefault: boolean
    currencyDefault: string
    emoji?: string
    defaultRequirement: DefaultRequirement
}

export interface CountryConfig {
    availableCurrencies?: string[]
    countries?: Country[]
    marketingOptIn?: boolean
}

export interface CurrencyConfig {
    currencies?: CountryConfig
    storedCurrency?: SelectedCurrency
}

export interface LabelLink {
    id?: string
    label: string
    to?: string
}

export interface JsonLdItem {
    '@context': string
    '@type'?: string
    [otherData: string]: any
}

export interface EventRowProps {
    eventId: number
    eventUrl: string
    eventName: string
    eventDate: string
    venueName: string
    location: string
    thumbnail: string
    onClickCapture?: () => void
}

export interface DefaultDispatchType {
    read: (params?: Record<string, any>) => void
}

export interface ProductionListSelectorType {
    date: string
    shortLocalDate: string
    day: string
    time: string
    dateWithFormat: string
    location: string
    venueName: string
    name: string
    id: number
    href: string
    performers: Performer[]
    analytics: { production: Production; index: number }
    isExternal?: boolean
    defaultVisible?: boolean
    ticketCount?: number
    venueCapacity?: number
    lat?: number
    long?: number
    minPrice?: number
    maxPrice?: number
    venueUrl?: string
    venueWebPath?: string
    venueOrganicUrl?: string
    isDriveDistance?: boolean
    isNearByDistance?: boolean
    category?: number
    isLowPricesDealsAvailable?: boolean
    isHomeOpener?: boolean
    openInNewTab?: boolean
    contentTags?: ContentTags[]
    onsaleDate?: string
    presale1Date?: string
    presale2Date?: string
    hasGiveaway?: boolean
    limitedInventoryBadgeLabel?: string
    onClickCapture?: () => void
}

export enum PageType {
    ConcertsCategory = 'Concerts Category',
    Home = 'Home',
    MatchupPage = 'Matchup Page',
    CustomPage = 'Custom Page',
    Performer = 'Performer',
    Region = 'Region',
    RegionDetails = 'Region Details',
    RegionList = 'Region List',
    Rewards = 'Rewards',
    SportsCategory = 'Sports Category',
    TheaterCategory = 'Theater Category',
    Venue = 'Venue',
    Search = 'Search',
    Checkout = 'Checkout',
    Category = 'Category',
    SubCategory = 'Sub-Category',
    StaticPage = 'Static Page',
    StudentDiscount = 'Pion',
    ListingManager = 'Listing Manager',
    OrderHistory = 'Order History',
    MyAccount = 'My Account',
    Login = 'Login',
    PasswordReset = 'Password Reset',
    Production = 'Production',
}

export interface ProductionListSelectorWithRowsInfoType {
    label: string
    title: string
    sublabel?: string
    href: string
    date: string
    shortLocalDate: string
    time: string
    day: string
    id: number
    analytics: { production: Production; index: number }
    performerId?: number
    isExternal?: boolean
    categoryId?: number
    isLowPricesDealsAvailable?: boolean
    isHomeOpener?: boolean
    limitedInventoryBadgeLabel?: string
    ticketCount?: number
    venueCapacity?: number
    onsaleDate?: string
    presale1Date?: string
    presale2Date?: string
    venueName: string
    location: string
    shouldOpenInNewTab?: boolean
    contentTags?: ContentTags[]
    hasGiveaway?: boolean
    rowButtonLabel?: string
    isImageProductionListCard?: boolean
    hideButton?: boolean
    onClickCapture?: () => void
}

export interface ImageSelectorType {
    external?: string
    mobile?: string
    desktop?: string
    thumbnail?: string
    fallbackUrl?: string
}

export interface PerformerImage extends ImageSelectorType {
    id: number
    name: string
    url: string
    category: string
    mainPerformerSubCategoryName: string
    productionCount: number
}

export type TypedOmit<T, K extends keyof T> = Omit<T, K>

export interface TableCell {
    value: string
    href?: string
    className?: string
}

export interface TableDef {
    headers: Array<TableCell>
    rows: Array<Array<TableCell>>
}

export interface City {
    city: string
    state: string
    country: string
}

export type QueryParam = string | string[] | undefined

export type CategoryIdMap<TValue> = Record<CATEGORY_IDS, TValue>

export interface Breadcrumb {
    url: string
    title: string
    isExternal?: boolean
}

export interface FilterOption<TValue = string> {
    label: string
    value?: TValue
}

export interface SelectOption<TValue extends string | number> {
    label: string
    value: TValue
}

export interface ObjectWithKeyIteration {
    [property: string]: any
}

export type CookieValue = ObjectWithKeyIteration | string

export interface DefaultProviderProps<Props = undefined, Params = undefined> {
    enabled?: boolean
    initialProps?: Props
    initialParams?: Params
    initialFetchDisabled?: boolean
}

export interface DefaultProviderPropsWithHeaders<Props, Params> extends DefaultProviderProps<Props, Params> {
    headers?: UseQueryHookParams['headers']
}

export interface DefaultStateType<DataType> {
    loading?: boolean
    fetching?: boolean
    data?: DataType
    error?: any
}

export interface Option<TValue> {
    value: TValue
    label: string
    dataTestId?: string
}

interface SubCategory {
    id: number
    name: string
    organicUrl: string
}

export interface Category {
    id: number
    name: string
    organicUrl: string
    subCategories?: SubCategory[]
    master?: boolean
}

/**
 * TODO: Some of the types needs to be rechecked for optional and mandatory properties.
 * Currently, this satisfies the use case for workflow 1.
 */
export interface Address {
    id?: number
    firstName: string
    lastName: string
    company?: string
    address1: string
    address2?: string
    city: string
    state: string
    zip: string
    country: string
    phoneNumber: string
    altPhoneNumber?: string
    sticky?: boolean
    saveAddress?: boolean
}

export interface CustomPricing {
    unitPriceOverride: number
    serviceChargeOverride: number
    localPrices: Record<string, number> | null
}

export interface Ticket {
    quantity: number
    listingId: string
    productionId: number
    deliveryMethodId?: number
    customPricing?: CustomPricing | null
    exclusiveListing?: boolean
    unitPrice?: number
    serviceCharge?: number
    localPrices?: { unitPrice?: number; serviceCharge?: number } | null
    seats?: string[]
}

export interface Order {
    orderId: number
    orderDate: string
    quantity: number
    section: string
    row: string
    totalPrice: number
}

export interface SalesTax {
    amount: number
    error: boolean
    salesTaxError?: string | null
    customErrorMessage?: string | null
    localPrices: { amount: number } | null
}

export interface Discounts {
    giftCardCodes: string[]
    applyStoreCredit: boolean
    promoCode?: string
    isUtmPromo: boolean
}

export interface QuoteInsuranceSettings {
    browserDetails: {
        name: string
        version: string
        deviceType: string
        osName: string
    }
    useDefault: boolean
    legacy: boolean
}

export interface HermesErrorDetails {
    errorCode: number
    errorMessage: string
    details: [string]
    message?: string
}

export interface HermesError {
    error: string
    details: HermesErrorDetails
}

export interface PartialSavedPaymentMethod {
    creditCardType: string | null
    shortCardNumber: string
    expirationMonth: string
    expirationYear: string
}

export interface SavedPaymentMethod extends PartialSavedPaymentMethod {
    id: number
    nonce: string | null
    braintreeId: string
    cvv: string | null
    sticky: boolean
    primaryCard: boolean
    newCard: boolean
}

export type SavedPaymentMethods = ReadonlyArray<SavedPaymentMethod>
