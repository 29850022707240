import dayjs from 'dayjs'
import type { ParsedUrlQuery } from 'querystring'
import { stringify } from 'querystring'

import type { QueryParam } from '@/types/app-types'

/**
 * Converts query parameter to string.
 *
 * If query parameter is an array (e.g. for `?param=1&param=2&param=3` query param is `['1', '2', '3']`) then function returns the first item.
 */
export const queryParamToStr = (queryParam: QueryParam): string | undefined => {
    const queryParamStr: string | undefined = Array.isArray(queryParam) ? queryParam[0] : queryParam
    return queryParamStr?.trim() || undefined
}

/**
 * Converts query parameter to string enum.
 *
 * If query parameter is an array (e.g. for `?param=1&param=2&param=3` query param is `['1', '2', '3']`) then function returns the first item.
 */
export const queryParamToStrEnum = <T extends string>(queryParam: QueryParam, allowedValues: T[]): T | undefined => {
    const queryParamStr: string | undefined = queryParamToStr(queryParam)
    return queryParamStr && allowedValues.includes(queryParamStr as T) ? (queryParamStr as T) : undefined
}

/**
 * Converts query parameter to integer.
 *
 * If query parameter is an array (e.g. for `?param=1&param=2&param=3` query param is `['1', '2', '3']`) then function returns the first item.
 */
export const queryParamToInt = (queryParam: QueryParam): number | undefined => {
    const queryParamStr: string | undefined = queryParamToStr(queryParam)
    return (queryParamStr && parseInt(queryParamStr)) || undefined
}

/**
 * Converts query parameter to date string.
 *
 * If query parameter is an array (e.g. for `?param=1&param=2&param=3` query param is `['1', '2', '3']`) then function returns the first item.
 */
export const queryParamToDateStr = (queryParam: QueryParam, dateFormat = 'YYYY-MM-DD'): string | undefined => {
    const queryParamStr: string | undefined = queryParamToStr(queryParam)
    if (!queryParamStr) {
        return undefined
    }
    const date = dayjs(queryParamStr)
    return date.isValid() ? date.format(dateFormat) : undefined
}

/**
 * Converts query parameter to boolean.
 *
 * If query parameter is an array (e.g. for `?param=true&param=false` query param is `['true', 'false']`) then function returns the first item.
 */
export const queryParamToBool = (queryParam: QueryParam): boolean | undefined => {
    const queryParamStr: string | undefined = queryParamToStr(queryParam)
    return queryParamStr === 'true'
}

/**
 * Get the search params during SSR and convert to string.
 *
 * Next v11 doesn't have ability to parse search paramaters, and both `query` and `params` conntains path names,
 * So here is work around to parse the search params.
 * This can be improved once we upgraded to Next v13
 */
type GetSsrSearchParamsArgType = { query?: ParsedUrlQuery; params?: ParsedUrlQuery }
export const getSsrSearchParams = ({ query = {}, params = {} }: GetSsrSearchParamsArgType) => {
    const customSearchParams = { ...query }
    // Strip and dynamic route parameters
    Object.keys(params).map((key) => {
        delete customSearchParams[key]
    })
    // Return query string
    return !!Object.keys(customSearchParams).length ? `?${stringify(customSearchParams)}` : ''
}
