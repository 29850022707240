import { DEFAULT_CURRENCY } from '@/context/internationalization/constants'
import type { ComposedListing, Production } from '@/types/app-types'
import { getPriceGroupId } from '@/utils/cookies'
import { daysUntil } from '@/utils/dates'
import { getMainPerformer } from '@/utils/production'

import type { CheckoutEvent } from '../types'

import { createGAItem, createUserInfo } from './common'
import {
    SELECT_LISTINGS_EVENT_NAME,
    SELECT_LISTING_CONTENT_TYPE,
    SELECT_SEARCH_SUGGESTION_EVENT_NAME,
    SELECT_PRODUCTION_EVENT_NAME,
    VIEW_LISTINGS_EVENT_NAME,
    VIEW_PRODUCTION_CONTENT_TYPE,
    VIEW_PRODUCTION_LIST_EVENT_NAME,
} from './constants'
import type {
    CheckoutECommerceEvent,
    CheckoutGiftCardPayment,
    ECommerceItem,
    GAECommerceEvent,
    SelectFocusSearchSuggestionEvent,
    SelectListingItem,
    SelectProductionEvent,
    SelectProductionItem,
    SelectSearchSuggestionEvent,
    SelectTicketEvent,
    UserInfo,
    ViewProductionEvent,
    ViewProductionItem,
    ViewProductionListEvent,
    ViewProductionListItem,
} from './types'

export const createECommerceItem = ({
    production,
    item_list_name = null,
}: {
    production: Production
    item_list_name: string | null
}): ECommerceItem => {
    const performer = getMainPerformer(production)

    return {
        ...createGAItem({ production, item_list_name }),
        performer: performer.name,
        performer_id: performer.id.toString(),
        production_id: production.id.toString(),
        production_city: production.venue.city,
        production_state: production.venue.state,
        venue: production.venue.name,
        venue_id: production.venue.id.toString(),
        days_until_production: daysUntil(production.utcDate),
    }
}

export const createViewProductionListItem = ({
    index,
    production,
    item_list_name = null,
}: {
    index: number
    production: Production
    item_list_name?: string | null
}): ViewProductionListItem => {
    return {
        ...createECommerceItem({ production, item_list_name }),
        index: index,
    }
}

export const createSelectProductionItem = ({
    index,
    production,
    item_list_name = null,
}: {
    index: number
    production: Production
    item_list_name?: string | null
}): SelectProductionItem => {
    return {
        ...createECommerceItem({ production, item_list_name }),
        index: index,
    }
}

export const createViewProductionItem = ({
    production,
    item_list_name = null,
}: {
    production: Production
    item_list_name?: string | null
}): ViewProductionItem => {
    return {
        ...createECommerceItem({ production, item_list_name }),

        total_inventory_available: production.listingCount,
        production_proximity: production.distance ? Math.round(production.distance / 25) * 25 : undefined, // round to 25 miles
    }
}

export const createSelectListingItem = (
    production: Production,
    listing: ComposedListing,
    quantity?: number,
): SelectListingItem => {
    return {
        ...createViewProductionItem({ production }),

        seat_tickets_available: Math.max(...listing.quantityOptionsList),

        item_variant: listing.section,
        quantity: quantity, // selected quantity

        listing_id: listing.id,
        price: listing.price,
        extended_price: quantity ? listing.price * quantity : undefined,

        stock_type: listing.stockTypeId,
        ticket_section: listing.section,
        ticket_row: listing.row,
    }
}

export const createCheckoutItem = (
    production: Production,
    listing: ComposedListing,
    quantity?: number,
): SelectListingItem => {
    return {
        ...createViewProductionItem({ production }),

        seat_tickets_available: production.ticketCount,

        item_variant: listing.section,
        quantity: quantity, // selected quantity

        price: listing.price,
        extended_price: quantity ? listing.price * quantity : undefined,

        stock_type: listing.stockTypeId,
        ticket_section: listing.section,
        ticket_row: listing.row,
        ticket_id: listing.id,
    }
}

/*
 * Translation functions for E-commerce Events
 */
export const createEmptyECommerceEvent = (): GAECommerceEvent => {
    return {
        ecommerce: null,
    }
}

export const createViewProductionListEvent = ({
    item_list_name = null,
    productions,
}: {
    item_list_name: string | null
    productions: Production[]
}): ViewProductionListEvent => {
    return {
        event: VIEW_PRODUCTION_LIST_EVENT_NAME,
        ecommerce: {
            url_path: window.location.pathname,
            item_list_name: item_list_name,
            price_group: getPriceGroupId(),
            items: productions
                .map((production, index) => createViewProductionListItem({ production, index, item_list_name }))
                .slice(0, 20),
        },
    }
}

export const createSelectProductionEvent = ({
    item_list_name = null,
    index,
    productions,
}: {
    item_list_name: string | null
    index: number
    productions: Production[]
}): SelectProductionEvent => {
    return {
        event: SELECT_PRODUCTION_EVENT_NAME,
        ecommerce: {
            item_list_name: item_list_name,
            price_group: getPriceGroupId(),
            items: productions.map((production) => createSelectProductionItem({ production, index, item_list_name })),
        },
    }
}

export const createSelectSearchSuggestionEvent = ({
    name,
    pageType,
    category,
    pageLocation,
    index,
}: {
    name: string
    pageType: string
    category?: string
    pageLocation: string
    index: number
}): SelectSearchSuggestionEvent => {
    return {
        event: SELECT_SEARCH_SUGGESTION_EVENT_NAME,
        ecommerce: {
            items: {
                name: name,
                pageType: pageType,
                category: category,
                pageLocation: pageLocation,
                index: index,
            },
        },
        search_suggestion_group: 'Query Suggestion',
    }
}

export const createSelectFocusSearchSuggestionEvent = ({
    title,
    focusGroup,
    pageLocation,
    index,
}: {
    title: string
    focusGroup: string
    pageLocation: string
    index: number
}): SelectFocusSearchSuggestionEvent => {
    return {
        event: SELECT_SEARCH_SUGGESTION_EVENT_NAME,
        ecommerce: {
            items: {
                title: title,
                pageLocation: pageLocation,
                index: index,
            },
        },
        search_suggestion_group: focusGroup,
    }
}

export const createViewProductionEvent = ({
    item_list_name = null,
    production,
}: {
    production: Production
    item_list_name: string | null
}): ViewProductionEvent => {
    return {
        event: VIEW_LISTINGS_EVENT_NAME,
        content_type: VIEW_PRODUCTION_CONTENT_TYPE,
        ecommerce: {
            price_group: getPriceGroupId(),
            items: [createViewProductionItem({ production, item_list_name })],
            transacting_currency: DEFAULT_CURRENCY,
        },
    }
}

export const createSelectListingEvent = (
    production: Production,
    listing: ComposedListing,
    quantity?: number,
): SelectTicketEvent => {
    return {
        event: SELECT_LISTINGS_EVENT_NAME,
        content_type: SELECT_LISTING_CONTENT_TYPE,
        ecommerce: {
            price_group: getPriceGroupId(),
            items: [createSelectListingItem(production, listing, quantity)],
            transacting_currency: DEFAULT_CURRENCY,
        },
    }
}

export const createCheckoutEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    let ecommerceEvent: CheckoutECommerceEvent = createEmptyECommerceEvent() as CheckoutECommerceEvent
    try {
        switch (eventInfo.type) {
            case 'checkout_login':
                ecommerceEvent = createCheckoutLoginEvent(eventInfo)
                break
            case 'begin_checkout':
                ecommerceEvent = createCheckoutBeginEvent(eventInfo)
                break
            case 'add_to_cart':
                ecommerceEvent = createCheckoutAddToCartEvent(eventInfo)
                break
            case 'add_shipping_info':
                ecommerceEvent = createCheckoutShippingEvent(eventInfo)
                break
            case 'add_payment_info':
                ecommerceEvent = createCheckoutAddPaymentInfoEvent(eventInfo)
                break
            case 'purchase_authorization':
                ecommerceEvent = createCheckoutPurchaseAuthorizationEvent(eventInfo)
                break
            case 'purchase_error':
                ecommerceEvent = createCheckoutPurchaseAuthorizationErrorEvent(eventInfo)
                break
            case 'purchase':
                ecommerceEvent = createCheckoutPurchaseEvent(eventInfo)
                break
            case 'select_insurance':
                ecommerceEvent = createCheckoutInsuranceEvent(eventInfo)
                break
        }
    } catch (e) {
        console.error('createCheckoutEvent', e)
    }

    return ecommerceEvent
}

export const createCheckoutPurchaseEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    const user: UserInfo = createUserInfo()

    const { order, productionPreviouslyPurchased } = eventInfo.data
    return {
        event: eventInfo.type,
        user,
        ecommerce: {
            price_group: getPriceGroupId(), // price group - STRING ID
            items: [createCheckoutItem(eventInfo.data.production, eventInfo.data.listing, eventInfo.data.quantity)],

            // totals
            currency: 'USD', // google
            subtotal: order.subTotal, // CUSTOM - sum of items.extended_price
            shipping: order.deliveryCost, // google - delivery fee
            service_fee: order.serviceCharge, // CUSTOM - service charge
            tax: order.salesTaxAmount, // google - sales tax
            discount: order.promo?.chargedAmount || 0, // google - sum of promo codes
            value: order.totalCharge - order.salesTaxAmount, // google - conversion value, no tax
            total: order.totalCharge - order.purchasedInsuranceAmount, // CUSTOM - total cost to customer, no insurance because it is billed separately
            // is_new_account: false, // bool - is this a new signup? - only after the login portion of checkout

            // purchase event ONLY
            transaction_id: order.orderId, // google - order id
            insurance_amount: order.purchasedInsuranceAmount, // CUSTOM - insurance
            // CUSTOM - sum of store credits used
            store_credit_amount: order.giftCardSet.reduce(
                (previousValue: CheckoutGiftCardPayment, currentValue: CheckoutGiftCardPayment) => {
                    if (currentValue.storeCredit) {
                        return previousValue.chargedAmount + currentValue.chargedAmount
                    }
                    return previousValue
                },
                0,
            ),
            // CUSTOM - sum of gift cards used (non-store credits)
            gift_card_amount: order.giftCardSet.reduce(
                (previousValue: CheckoutGiftCardPayment, currentValue: CheckoutGiftCardPayment) => {
                    if (currentValue.storeCredit) {
                        return previousValue
                    }
                    return previousValue.chargedAmount + currentValue.chargedAmount
                },
                0,
            ),
            loyalty_amount: 0.0, // CUSTOM - sum of loyalty amount burned
            coupon: order.promo?.code, // google - promo code used
            promo_amount: order.promo?.chargedAmount || 0, // CUSTOM - sum of promo codes used - same as discount
            payment_amount: order.totalCharge - order.purchasedInsuranceAmount, // CUSTOM - amount charged to customer from us (does not include insurance)
            production_previously_purchased: productionPreviouslyPurchased, // CUSTOM
        },
    }
}

export const createCheckoutPurchaseAuthorizationEvent = (eventInfo: CheckoutEvent) => {
    const user: UserInfo = createUserInfo()
    const purchasedInsuranceSelected = eventInfo.data?.order?.globalState.order.insurance?.selectedInsuranceOption
    const insuranceOptions = eventInfo.data?.order.globalState.order.insurance?.insuranceOffer?.options

    const insuranceCalculator = (objects: any[]) => {
        const filteredObjects = objects?.filter((obj) => obj?.quoteId === purchasedInsuranceSelected)
        return filteredObjects || 0
    }

    return {
        event: eventInfo.type,
        user,
        ecommerce: {
            items: [createCheckoutItem(eventInfo.data?.production, eventInfo.data?.listing, eventInfo.data?.quantity)],
            // totals
            currency: 'USD', // google
            subtotal: eventInfo.data?.order.quote.totalPreTax, // CUSTOM - sum of items.extended_price
            shipping: eventInfo.data?.order.quote.deliveryCost, // google - delivery fee
            service_fee: eventInfo.data?.order.quote?.ticketList?.[0]?.serviceCharge, // CUSTOM - service charge
            tax: eventInfo.data?.order.quote?.salesTax.amount, // google - sales tax
            discount: eventInfo.data?.order.globalState?.promo?.promoDiscount || 0, // google - sum of promo codes
            value: eventInfo.data?.order.quote.totalCharge - eventInfo.data.order.quote?.salesTax.amount, // google - conversion value, no tax
            total:
                eventInfo.data?.order.quote.totalCharge - insuranceCalculator(insuranceOptions)?.[0]?.totalPrice ||
                eventInfo.data?.order.quote.totalCharge, // CUSTOM - total cost to customer, no insurance because it is billed separately
            insurance_amount: insuranceCalculator(insuranceOptions)?.[0]?.totalPrice || 0, // CUSTOM - insurance
            coupon: eventInfo.data?.order.globalState.promoValid, // google - promo code used
            promo_amount: eventInfo.data?.order.globalState?.promo?.promoDiscount || 0, // CUSTOM - sum of promo codes used - same as discount
            payment_amount:
                eventInfo.data.order?.quote?.totalCharge - insuranceCalculator(insuranceOptions)?.[0]?.totalPrice ||
                eventInfo.data.order?.quote?.totalCharge, // CUSTOM - amount charged to customer from us (does not include insurance)
            production_previously_purchased: eventInfo.data.productionPreviouslyPurchased, // CUSTOM
        },
    }
}

export const createCheckoutPurchaseAuthorizationErrorEvent = (eventInfo: any) => {
    if (!eventInfo.data) {
        return {
            event: 'purchase_error',
            error_code: eventInfo.errorCode,
            ecommerce: {},
        }
    }
    return {
        event: eventInfo.type,
        error_code: eventInfo.data.errorCode,
        ecommerce: eventInfo.data.ecommerce,
    }
}

export const createCheckoutAddPaymentInfoEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    return {
        event: eventInfo.type,
        ecommerce: {
            items: [createCheckoutItem(eventInfo.data.production, eventInfo.data.listing, eventInfo.data.quantity)],
            payment_type: eventInfo.data.paymentMethod, // google - ALL CAPS - CREDIT_CARD/PAYPAL/APPLE_PAY
        },
    }
}

export const createCheckoutLoginEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    const user: UserInfo = createUserInfo()

    return {
        event: eventInfo.type,
        user,
        ecommerce: {
            is_new_account: !user.user_id, // bool - is this a new signup? - only after the login portion of checkout
            login_type: eventInfo.data.loginType, // google - guest/password/facebook
        },
    }
}
export const createCheckoutShippingEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    return {
        event: eventInfo.type,
        ecommerce: {
            items: [createCheckoutItem(eventInfo.data.production, eventInfo.data.listing, eventInfo.data.quantity)],
            shipping_tier: eventInfo.data.deliveryMethod.shortDescription, // google, delivery_method
        },
    }
}

export const createCheckoutBeginEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    const items = [createCheckoutItem(eventInfo.data.production, eventInfo.data.listing, eventInfo.data.quantity)]
    const subtotal = items.reduce((previousValue, currentValue) => {
        return currentValue.extended_price ? previousValue + currentValue.extended_price : previousValue
    }, 0)
    return {
        event: eventInfo.type,
        ecommerce: {
            price_group: getPriceGroupId(), // price group - STRING ID
            items,
            currency: 'USD', // google
            subtotal: subtotal, // CUSTOM - sum of items.extended_price
            shipping: 0, // google - delivery fee
            service_fee: 0, // CUSTOM - service charge
            tax: 0, // google - sales tax
            discount: 0, // google - sum of promo codes
            value: subtotal, // google - conversion value, no insurance, no tax
            total: subtotal, // CUSTOM - total cost to customer, no insurance because it is billed separately
        },
    }
}

export const createCheckoutAddToCartEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    const items = [createCheckoutItem(eventInfo.data.production, eventInfo.data.listing, eventInfo.data.quantity)]
    return {
        event: eventInfo.type,
        ecommerce: {
            items,
        },
    }
}

export const createCheckoutInsuranceEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    return {
        event: eventInfo.type,
        ecommerce: eventInfo.data.ecommerce,
    }
}
