export const LISTING_ROW_IMAGE_HEIGHT = 414
export const LISTING_ROW_IMAGE_WIDTH = 600
export const LISTING_ROW_IMAGE_SIZE = {
    height: LISTING_ROW_IMAGE_HEIGHT,
    width: LISTING_ROW_IMAGE_WIDTH,
}

export const LOCATION_TBD = 'Location: TBD'
export const LOCATION = 'Location'
export const VENUE_TBD = 'Venue: TBD'
export const VENUE = 'Venue'
export const TBD = 'TBD'
export const TO_BE_DETERMINED = 'To Be Determined'
export const BULLET_SYMBOL = '\u2022'

export const LISTING_SCORE_LABELS: Record<number, string> = {
    10: 'Fantastic',
    9: 'Excellent',
    8: 'Great',
    7: 'Very Good',
    6: 'Good',
}

export const LISTING_ICONS = {
    CAR: 'car',
    CLUB_SEATS: 'club-seats',
    DOOR: 'door',
    FAMILY_NON_ALC: 'family-non-alc',
    FIRST_IN_SECTION: 'first-in-section',
    FAST_FOOD: 'fast-food',
    GIFT_BOX: 'gift-box',
    HANDSHAKE: 'handshake',
    RESTRICTED_VIEW: 'restricted-view',
    SEAT_SAVER: 'seat-saver',
    STANDING_ROOM: 'standing-room',
    SUITES: 'suites',
    VIP: 'vip',
} as const

export const LISTING_PERKS: Record<
    string,
    {
        title: string
        description: string
        icon: (typeof LISTING_ICONS)[keyof typeof LISTING_ICONS]
    }
> = {
    ['CLUB_ACCESS']: {
        title: 'Access to Club/Lounge',
        description: 'Gain entry into the exclusive Club & Lounge area + included amenities.',
        icon: LISTING_ICONS.DOOR,
    },
    ['FOOD_AND_BEVERAGE_INCLUDED']: {
        title: 'Unlimited Food and Beverage',
        description: 'Enjoy all-inclusive food & beverage options.',
        icon: LISTING_ICONS.FAST_FOOD,
    },
    ['FOOD_AND_BEVERAGE_VOUCHER']: {
        title: 'Food & Beverage Voucher Included',
        description: 'Redeem for select food & beverages at the venue.',
        icon: LISTING_ICONS.FAST_FOOD,
    },
    ['MEET_AND_GREET']: {
        title: 'Meet-and-greet',
        description: 'Fan out face to face with a live meet-and-greet.',
        icon: LISTING_ICONS.HANDSHAKE,
    },
    ['MEMORABILIA']: {
        title: 'Memorabilia',
        description: 'Collect one-of-a-kind keepsakes.',
        icon: LISTING_ICONS.GIFT_BOX,
    },
    ['PARKING']: {
        title: 'Parking Included',
        description: 'Take the drama out of driving with a reserved spot or parking pass.',
        icon: LISTING_ICONS.CAR,
    },
    ['VIP']: {
        title: 'VIP',
        description: 'Score private VIP entry, parking &/or exclusive perks. Check your event details for specifics.',
        icon: LISTING_ICONS.VIP,
    },
}

export const SEAT_FEATURES: Record<string, any> = {
    ['CLUB_SEATS']: {
        title: 'Club Seats',
        description: 'Access amazing sightlines, elevated seating & more.',
        icon: LISTING_ICONS.CLUB_SEATS,
    },
    ['FAMILY_NON_ALCOHOL']: {
        title: 'Family/Non-Alcoholic Section',
        description: 'Experience it together in a family-friendly, non-alcoholic section.',
        icon: LISTING_ICONS.FAMILY_NON_ALC,
    },
    ['FIRST_ROW_IN_SECTION']: {
        title: 'Front of Section',
        description: 'Immerse yourself in the action with front row access.',
        icon: LISTING_ICONS.FIRST_IN_SECTION,
    },
    ['RESTRICTED_VIEW']: {
        title: 'Restricted/Obstructed View',
        description: 'View your event with a limited or partial view.',
        icon: LISTING_ICONS.RESTRICTED_VIEW,
    },
    ['SEAT_SAVER']: {
        title: 'Seat Saver',
        description:
            'This Seat Saver listing is a service backed by our 100% buyer guarantee in which the seller is offering to buy tickets for you for a fixed price in the listed section.',
        icon: LISTING_ICONS.SEAT_SAVER,
    },
    ['STANDING_ROOM_ONLY']: {
        title: 'Standing Room Only',
        description: 'Take in the event without assigned seating.',
        icon: LISTING_ICONS.STANDING_ROOM,
    },
    ['SUITE']: {
        title: 'Suite',
        description: 'Entertain with elevated amenities & premium services.',
        icon: LISTING_ICONS.SUITES,
    },
}

// {venueId_venueName}/{layoutId}_{layoutName}/{YYYYMMDD}/{image_type}/{sectionName}_{sectionId}.jpeg
export const VALID_IMAGEFILEPATH_REGEX = /^\d+_[^\/]+\/[^\/]+\/\d{8}\/[a-zA-Z]+\/([^\/]+_)?[\d]+\.jpeg$/