import type { OptimizelyUserContext } from '@optimizely/optimizely-sdk'
import type { ReactSDKClient } from '@optimizely/react-sdk'
import type { UserInfo } from '@optimizely/react-sdk/dist/utils'
import type { ServerResponse } from 'http'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { COOKIES } from '@/constants'
import { OPTIMIZELY_OFF_VALUE } from '@/optimizely/constants'
import { trackOptimizelyExperiment } from '@/utils/analytics'
import { getCookie, setCookie } from '@/utils/cookies'

type ActivateObject = {
    userId: string
    experiment: {
        [key: string]: string
    }
    variation: {
        [key: string]: string
    }
}

export type OverriddenOptimizelyClient = Pick<
    ReactSDKClient,
    'isFeatureEnabled' | 'activate' | 'setForcedVariation' | 'track' | 'getFeatureVariables'
>

const OptimizelyOnOptions = ['on', 'true', 'enabled', 'default']
const OptimizelyOffOptions = [OPTIMIZELY_OFF_VALUE, 'false', 'disabled']

/**
 * **Note:** Be sure the provided `user` is different from `optimizely.user`
 * (e.g. use `cloneDeep(optimizely.user)` and then mutate the clone)
 */
export const applyUserInfo = (optimizely: ReactSDKClient | null, user: UserInfo): void => {
    if (!isEqual(user, optimizely?.user)) {
        optimizely?.setUser(user)
    }
}

export const applyOptimizelyOverrides = <TOptimizelyClient extends OverriddenOptimizelyClient>(
    optimizelyClient: TOptimizelyClient,
    optimizelyInitString: string | null | undefined,
    userId: string,
): TOptimizelyClient => {
    optimizelyInitString = optimizelyInitString?.trim()

    if (!optimizelyInitString || OptimizelyOnOptions.includes(optimizelyInitString.toLowerCase())) {
        // (Default) Use Optimizely feature flags and experiments as normally
        return optimizelyClient
    } else if (OptimizelyOffOptions.includes(optimizelyInitString.toLowerCase())) {
        // Ignore all Optimizely feature flags and experiments
        optimizelyClient.isFeatureEnabled = () => false
        optimizelyClient.activate = () => null
        optimizelyClient.track = () => null
        optimizelyClient.getFeatureVariables = () => ({})
        return optimizelyClient
    } else {
        // Enable only those Optimizely feature flags and experiments that are provided in the list, e.g. experiment2:variation3

        const items = optimizelyInitString.split(',')
        for (const item of items) {
            if (item.includes(':')) {
                // This is an experiment
                const [experimentKey, variation] = item.split(':')

                // In order to force a variation the experiment needs to be running
                optimizelyClient.setForcedVariation(experimentKey.trim(), userId, variation.trim())
            }
        }

        return optimizelyClient
    }
}

export const activateNotificationCallback = (activateObject: ActivateObject): void => {
    if (typeof document === 'undefined') return

    // get cookies, see if experiment has been tracked
    const cookie = getCookie(document.cookie, `optimizely-experiments`)
    const experimentCookie = isEmpty(cookie)
        ? []
        : (cookie as Array<{
              key: string
              variation: string
          }>)
    const match = find(experimentCookie, {
        key: activateObject.experiment['key'],
        variation: activateObject.variation['key'],
    })

    if (experimentCookie.length === 0 || !match) {
        trackOptimizelyExperiment(activateObject.experiment['key'], activateObject.variation['key'])
        const experiment = {
            key: activateObject.experiment['key'],
            variation: activateObject.variation['key'],
        }
        experimentCookie.push(experiment)
        document.cookie = `optimizely-experiments=${JSON.stringify(experimentCookie)}; path=/;`
    }
}

export type OptimizelyDecisionCookieData = {
    flagKey: string
    variationKey: string | null
    enabled: boolean
    variables: Record<string, unknown>
    userId: string
}

export type OptimizelyDecisionCookieResult = OptimizelyDecisionCookieData | undefined

export const setOptimizelyDecisionCookie = (
    userContext: OptimizelyUserContext,
    optimizelyUserId: string,
    feature: string,
    res: ServerResponse,
) => {
    const { flagKey, variationKey, enabled, variables } = userContext.decide(feature, [])
    const decisionCookieData: OptimizelyDecisionCookieData = {
        flagKey,
        variationKey,
        enabled,
        variables,
        userId: optimizelyUserId,
    }

    const cookieExpiration = new Date()
    cookieExpiration.setTime(cookieExpiration.getTime() + 365 * 24 * 60 * 60 * 1000)
    setCookie(res, {
        key: `${COOKIES.OPTIMIZELY_DECISION_COOKIE}-${flagKey}`,
        value: JSON.stringify(decisionCookieData),
        path: '/',
        expirationDate: cookieExpiration,
        isSecure: process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'stage',
    })
}
