import { createSelector } from 'reselect'

import type { LocationStateType } from '@/context/location/types'
import { normalizeLatLong } from '@/utils/location/utils'

export const locationGlobalSelector = (state: LocationStateType) => state

export const getNameSelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.data) {
            return
        }

        // sometimes the city returns "" so we fallback to region
        return state.data.city || state.data.region || state.data.countryName
    },
)

export const getLatLongSelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.data) {
            return
        }

        return normalizeLatLong(state.data)
    },
)

export const getDetectedCountrySelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.detectedLocation) {
            return
        }

        return state.detectedLocation.countryName
    },
)

export const getRegionSelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.data) {
            return
        }

        return state.data.region
    },
)

export const getCountrySelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.data) {
            return
        }

        return state.data.countryName
    },
)

export const getDropdownNameSelector = createSelector(
    locationGlobalSelector,
    (state: LocationStateType): string | undefined => {
        if (!state.data) {
            return
        }

        const {
            data: { city, countryName, region },
        } = state

        // edge cases for no city and / or region
        if (!countryName && !region && !city) {
            return ''
        }

        if (countryName && !region && !city) {
            return countryName
        }

        if (countryName && region && !city) {
            return `${region}, ${countryName}`
        }

        const isUSA = countryName === 'United States'
        const cityStateSuffix = `${isUSA ? region : countryName}`

        return `${city}, ${cityStateSuffix}`
    },
)
